import useServiceFactory from "../serviceFactory";
const apiUrl = `/taller/mantenimiento/OrdenesTrabajo`;
const useApiOrdenServicio = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, {params}),
        obtener: async (id) => service.get(`${apiUrl}/${id}`, {}),
        guardar: async (params) => service.post(`${apiUrl}`, params),
        obtenerUnidad: async (id) => service.put(`${apiUrl}/unidades/${id}`,{}),
        obtenerServicios: async (id) => service.put(`${apiUrl}/mantenimientos/${id}/actividades`,{}),
        obtenerRefacciones: async (id) => service.put(`${apiUrl}/actividades/${id}/refacciones`,{}),
        esReprogramacion: async (id,params) => service.get(`${apiUrl}/${id}/esReprogramacion`,{params}),
        actualizar: async (id,params) => service.put(`${apiUrl}/${id}`, params),
        obtieneHistorial: async (id) => service.get(`${apiUrl}/${id}/historial`,{}),
        cancelar: async (id,params) => service.patch(`${apiUrl}/${id}/cancelar`,params),  
        obtieneCheckList: async (id) => service.get(`${apiUrl}/checkList/${id}`,{}),   
        confirmar: async (id,params) => service.put(`${apiUrl}/${id}/confirmar`,params), 
        actualizarInventario: async () => service.post(`${apiUrl}/actualizarInventarioOrdenesTrabajo`),
        terminarOrden :async (id) => service.put(`${apiUrl}/${id}/terminarOrden`, {}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `OrdenesDeTrabajo.xlsx`
        }),
        entradaTaller: async (id,params) => service.put(`${apiUrl}/entradaTaller/${id}`, params)
    }
};

export default useApiOrdenServicio;