import React from "react";

import "./ButtonIcon.css";
import IconLibrary from "../iconLibrary";

const ButtonIcon = ({ title, type, iconName, className, onClick, disabled, marginTop, color, spin }) => {
    function handleClick() {
        if (!disabled && onClick != null) {
            onClick();
        }
    }

    return (
        <button
            title={title}
            style={{ marginTop: marginTop, color: color }}
            type={type == null ? "button" : type}
            className={`button-icon ${iconName} ${className ?? ""} ${type ?? ""} ${disabled ? "disabled" : ""} ${
                spin ? "fa-spin" : ""
            }`}
            onClick={handleClick}
        >
            <IconLibrary iconName={iconName} />
        </button>
    );
};

export default ButtonIcon;
