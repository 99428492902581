import {Button} from "primereact/button";
import React from "react";
import FormCheckBoxList from "../../../../shared/forms/FormFields/FormCheckBoxList";
import Field from "../../../../shared/forms/Field";
import Form from "../../../../shared/forms/Form";
import FormAutocomplete from "../../../../shared/forms/FormFields/FormAutocomplete";
import FormTextBox from "../../../../shared/forms/FormFields/FormTextBox";
import FormCheckBox from "../../../../shared/forms/FormFields/FormCheckBox";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import FormDropDown from "../../../../shared/forms/FormFields/FormDropDown";
import FormMultiSelect from "../../../../shared/forms/FormFields/FormMultiSelect";



const FiltrarModal = () => {

    const {filtro, aplicarFiltro} = useSeguimientoMonitoreo();
    const {value, setValue, modalVisible, ocultarFiltro} = filtro

    if (!modalVisible){
        return null;
    }

    const onFilterKeyDown = async (event) => {
        if (event.code === 'Enter') {
            await aplicarFiltro()
        }
    }


    return (
        <div style={{width: '350px'}} className="seg-modal" onKeyDown={onFilterKeyDown}>
            <div className={'seg-dialog-header'}>
                <div className={'seg-dialog-title'}>
                    Seleccionar filtros
                </div>
                <Button icon="pi pi-times" className="p-button-rounded p-button-text seg-dialog-close" onClick={ocultarFiltro} />
            </div>
            <Form columns={1} values={value} onChange={setValue}
                  className={`form-container`}>
                <Field field={'empresaIds'} source={'empresas'} fluid={true} render={FormCheckBoxList} />
                <Field field={'estacionIds'} label={'Estación'} source={'estaciones'}  render={FormMultiSelect} />
                <Field field={'cliente'} label={'Cliente'} source={'clientes'}  render={FormAutocomplete} />
                <Field field={'subCliente'} label={'Sub Cliente'} source={'subClienteNombres'}  showClear={true}  render={FormDropDown} />
                <Field field={'unidad'} label={'Unidad'} render={FormTextBox} />
                <Field field={'caja'} label={'Caja'}  render={FormTextBox} />
                <Field field={'soloExpeditados'} checkboxLabel={'Solo Expeditados'} render={FormCheckBox}/>
                {/*<Field field={'todasLasCajas'} checkboxLabel={'Todas las Cajas'} render={FormCheckBox}/>*/}
            </Form>

            <div className={'fluid'} class={'dialog-footer center-buttons'}>
                <Button label="Aplicar"  className={'p-button-sm'} onClick={()=>aplicarFiltro()} autoFocus />
            </div>
        </div>
    )
}
export default FiltrarModal;