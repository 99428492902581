/* eslint-disable react-hooks/exhaustive-deps */
import TarjetaViaje from "./TarjetaViaje";
import React, {useEffect, useState} from "react";
import {useSeguimientoMonitoreo} from "./SeguimientoMonitoreoProvider";

const TarjetasViaje = ({handleTooltip,onHideTooltip}) => {
    const {
        viajes,
        filtro,
        viajesFiltrados,
        onSetLocalFilter
    } = useSeguimientoMonitoreo();

    const {texto,indicadorId, colorId} = filtro.value

    const aplicarFiltroLocal = () => {
        const cartaPorte = parseInt(texto)
        const filtroTexto = texto.toLowerCase().trim()
        let viajesFiltrados = texto.length > 0 ? viajes.filter(e =>
            e.caja?.toLowerCase()?.includes(filtroTexto) ||
            e.unidad?.toLowerCase()?.includes(filtroTexto) ||
            e.cliente?.toLowerCase()?.includes(filtroTexto) ||
            e.operador?.toLowerCase()?.includes(filtroTexto) ||
            e.ruta?.toLowerCase()?.includes(filtroTexto) ||
            e.subCliente?.toLowerCase()?.includes(filtroTexto) ||
            (!isNaN(cartaPorte) && e.cartaPorte === cartaPorte ) ||
            e.idViaje === filtroTexto ||
            e.idViajeRetorno === filtroTexto
        ) : viajes;

        if (indicadorId != null){
            viajesFiltrados = viajesFiltrados.filter(e=> e.indicadores != null && e.indicadores[indicadorId]?.color === colorId);
        }
        onSetLocalFilter(viajesFiltrados);
    }
    useEffect(() => {
        aplicarFiltroLocal()
    }, [viajes]);

    const [timer, setTimer] = useState(null)

    useEffect(() => {
        if (timer != null)
            clearTimeout(timer)
        setTimer(setTimeout(aplicarFiltroLocal,[500]))
    }, [texto]);

    useEffect(()=>{
        aplicarFiltroLocal()
    },[indicadorId,colorId])


    return (
        <div className={'seg-viajes'}>
            {viajesFiltrados && viajesFiltrados.map((viaje) =>
                <TarjetaViaje key={`${viaje.viajeId}-${viaje.unidadId}`}
                              viaje={viaje}
                              onTooltipIndicador={handleTooltip}
                              onHideTooltip={onHideTooltip}
                />
            )}
        </div>
    )
}
export default TarjetasViaje;