/* eslint-disable react-hooks/exhaustive-deps */
import {TabPanel, TabView} from "primereact/tabview";
import {useReducer} from "react";
import formReducer from "../../shared/forms/formReducer";
import ConfigSaas from "./ConfigSaas";
import {useEffect} from "react";
import useApiEmpresas from "../../api/catalogos/useApiEmpresas";
import ConfigFreightVerify from "./ConfigFreightVerify";
import ConfigEmailFacturacion from "./ConfigEmailFacturacion";
import {Button} from "primereact/button";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import EmpredaValidacionEsquema from "./EmpresaValidationEsquema";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";

const DetalleEmpresa = ({id, onSave, onClose}) => {
    const defaultEmpresa = () => ({
        entity: {
            saasConfig: {},
            freightVerifyConfig: {},
            emailFacturacionSettings: {
                applicationUrl: "",
            },
        },
    });
    const api = useApiEmpresas();

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEmpresa);

    const obtener = async () => {
        const item = await api.obtener(id);
        dispatch({type: "update", value: item});
    };

    useEffect(() => {
        if (id > 0) obtener().then();
    }, []);

    const guardar = async () => {
        const valid = EmpredaValidacionEsquema.isValid(entity);
        if (valid) {
            await api.guardar(entity);
            onSave();
            onClose();
        }
    };

    const actualizar = (value, name) => {
        dispatch({type: "update", value: value, name: name});
    };

    return (
        <>
            <div className="form-container full-height">
                <TabView activeIndex={0}>
                    <TabPanel header="Generales">
                        <div className="form-row">
                            <FieldTextBox
                                label="Nombre"
                                name="nombre"
                                value={entity?.nombre}
                                onChange={actualizar}
                                colMd={12}
                                required={true}
                                errors={errors}
                            />
                            <FieldTextBox
                                label="Nombre Corto"
                                name="nombreCorto"
                                value={entity?.nombreCorto}
                                onChange={actualizar}
                                colMd={6}
                                required={true}
                                errors={errors}
                            />
                            <FieldTextBox
                                label="Zona Horaria"
                                name="timeZone"
                                value={entity?.timeZone}
                                onChange={actualizar}
                                colMd={6}
                                required={true}
                                errors={errors}
                            />
                            <FieldTextBox
                                label="Contenedor de Documentos"
                                name="contenedorDocumentos"
                                value={entity?.contenedorDocumentos}
                                onChange={actualizar}
                                colMd={6}
                                required={true}
                                errors={errors}
                            />
                            <FieldTextBox
                                label="Correo Grupal"
                                name="correo"
                                value={entity?.correo}
                                onChange={actualizar}
                                colMd={6}
                                required={true}
                                errors={errors}
                            />
                            <FieldDropDown
                                label="Moneda Base"
                                name="monedaId"
                                value={entity?.monedaId}
                                onChange={actualizar}
                                colMd={6}
                                source={"Monedas"}
                                required={true}
                                errors={errors}
                                valueById={true}
                            />
                            <FieldMultiSelect
                                label="Monedas Disponibles"
                                name="monedasDisponiblesIds"
                                value={entity?.monedasDisponiblesIds}
                                onChange={actualizar}
                                colMd={6}
                                source={"Monedas"}
                                required={true}
                                errors={errors}
                                valueAsId={true}
                            />
                            <FieldDropDown
                                label="Sistema Métrico"
                                name="sistemaMetricoId"
                                value={entity?.sistemaMetricoId}
                                onChange={actualizar}
                                colMd={6}
                                source={"SistemasMetricos"}
                                required={true}
                                errors={errors}
                                valueById={true}
                            />
                            <FieldTextBox label="RFC"
                                          name="rfc"
                                          value={entity?.rfc}
                                          onChange={actualizar}
                                          colMd={6}
                                          required={true}
                                          errors={errors}/>
                            <FieldTextBox label="Correo Finanzas"
                                          name="correoFinanzas"
                                          value={entity?.correoFinanzas}
                                          onChange={actualizar}
                                          colMd={6}
                                          required={true}
                                          errors={errors}/>
                        </div>
                    </TabPanel>
                    <TabPanel header="Configuración Saas">
                        <ConfigSaas config={entity.saasConfig} onChange={actualizar} errors={errors?.saasConfig}/>
                    </TabPanel>
                    <TabPanel header="Configuración Freight Verify">
                        <ConfigFreightVerify config={entity.freightVerifyConfig} onChange={actualizar}/>
                    </TabPanel>
                    <TabPanel header="Configuracion Email Facturacion">
                        <ConfigEmailFacturacion config={entity.emailFacturacionSettings} onChange={actualizar}/>
                    </TabPanel>
                </TabView>
            </div>
            <div className="form-footer">
                <Button label="Guardar" className="p-button-outlined" type="button" onClick={guardar}/>
                <Button
                    label="Cerrar"
                    className="p-button-outlined p-button-secondary"
                    type="button"
                    onClick={onClose}
                />
            </div>
        </>
    );
};

export default DetalleEmpresa;
