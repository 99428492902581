/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {Sidebar} from "primereact/sidebar";
import constantes from "../../catalogos/constantes";
import useForm from "../../shared/forms/useForm";
import {useState} from "react";
import Field from "../../shared/forms/Field";
import Form from "../../shared/forms/Form";
import moment from "moment";
import FormTabView from "../../shared/forms/Tabs/FormTabView";
import General from "./Edicion/general";
import Cargos from "./Edicion/cargos";
import {Button} from "primereact/button";
import {serialize} from "object-to-formdata";
import useApiIncidencia from "../../api/administracion/useApiIncidencia";
import Cobro from "./Edicion/cobro";
import alerts from "../../shared/alerts";

const IncidenciaDetalle = ({incidenciaId, onCancel, onSaved, puedeEditar, onRefresh, empleado}) => {
    const initialValues = {
        incidencia: {
            id: 0,
            fechaIncidencia: moment().startOf('day')._d,
            solicitante: {
                id: empleado?.id,
                nombre: empleado?.nombre
            }
        }
    };
    const visible = incidenciaId !== null;
    const [submitted, setSubmitted] = useState(false);
    const {
        values: item,
        setValues,
        onChange,
        onValidate,
        isValid
    } = useForm(initialValues);
    const api = useApiIncidencia();
    const [cobrar, setCobrar] = useState(false);

    const onLoad = async () => {
        if (incidenciaId === 0 || incidenciaId === null) {
            setValues(initialValues);
            return;
        }

        let result = await api.obtener(incidenciaId);
        result.saldoAnterior = result.saldo;
        const item = {
            incidencia: result
        };
        setValues(item);
    };

    useEffect(() => {
        onLoad().then();
    }, [incidenciaId]);

    const onSave = async () => {
        setSubmitted(true);

        if (!isValid)
            return;

        const formData = serialize(item?.incidencia ?? {}, {indices: true, dotsForObjectNotation: true});
        await api.guardar(formData);
        onSaved();
    }

    const onConfirm = () => {
        setCobrar(true);
    };

    const actualizarEstatus = async (estatusId) => {
        const msg = estatusId === constantes.estatusIncidencias.Cancelada ? '¿Desea cancelar la incidencia?' : '¿Desea cerrar la incidencia?';
        if ((await alerts.preguntarSiNo(msg)) === true) {
            const formData = serialize({estatus: estatusId}, {indices: true, dotsForObjectNotation: true});
            await api.actualizarEstatus(item.incidencia.id, formData);
            onSaved();
        }
    };

    const Cerrar = async () => {
        if ((await alerts.preguntarSiNo("¿Desea cerrar la incidencia?")) === true) {

            const formData = serialize(item.incidencia ?? {}, {indices: true, dotsForObjectNotation: true});
            await api.cerrar(formData);
            onSaved();
        }
    };

    return (
        <>
            <Sidebar visible={visible} position="right" dismissable={false}
                     className="p-sidebar-bg" onHide={onCancel}>
                <Form columns={1} values={item} onChange={onChange}
                      onValidate={onValidate} submitted={submitted} formDisabled={item?.incidencia?.id > 0}
                      className={`form-container full-height ${submitted ? 'submitted' : ''}`}>
                    <FormTabView>
                        <Field title="General" field={'incidencia'}
                               render={(props) => <General {...props} puedeEditar={puedeEditar}/>}/>
                        {item?.incidencia?.id > 0 &&
                            <Field title="Cargos" field={'incidencia'}
                                   render={(props) => <Cargos {...props} puedeEditar={puedeEditar}
                                                              onRefresh={onRefresh}/>}/>
                        }
                    </FormTabView>
                </Form>
                <div className="form-footer">
                    <Button label="Salir" onClick={onCancel} className="p-button-outlined"/>
                    {item?.incidencia?.cargos?.length > 0 && (item?.incidencia?.saldo === 0 || item?.incidencia?.saldo < 0) && item?.incidencia?.estatus === constantes.estatusIncidencias.EnProceso &&
                        <>
                            <Button label="Cancelar" type="button" className="p-button-warning"
                                    onClick={() => actualizarEstatus(constantes.estatusIncidencias.Cancelada)}/>
                            <Button label="Cerrar" type="button"
                                    onClick={() => actualizarEstatus(constantes.estatusIncidencias.Cerrada)}/>
                        </>
                    }
                    {(item?.incidencia?.id === 0 || item?.incidencia?.estatus === constantes.estatusIncidencias.Creada) &&
                        <Button label="Guardar" type="button"
                                onClick={onSave}/>
                    }

                    {item?.incidencia?.id > 0 && item?.incidencia?.estatus === constantes.estatusIncidencias.Creada &&
                        <Button label="Cerrar" type="button"
                                onClick={Cerrar}/>
                    }

                    {item?.incidencia?.estatus === constantes.estatusIncidencias.Creada && item?.incidencia?.cargos?.filter(c => !c.cobrado).length > 0 &&
                        <Button label="Cobrar" type="button" onClick={onConfirm}/>
                    }
                </div>
            </Sidebar>
            <Cobro visible={cobrar} onClose={() => setCobrar(false)} incidencia={item?.incidencia}
                   onSaved={() => {
                       onSaved();
                       setCobrar(false);
                   }}/>
        </>
    );
};

export default IncidenciaDetalle;
