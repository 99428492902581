import React from "react";
import Form from "../../../shared/forms/Form";
import Field from "../../../shared/forms/Field";
import FormTextBox from "../../../shared/forms/FormFields/FormTextBox";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import constantes from "../../../catalogos/constantes";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import FormTime from "../../../shared/forms/FormFields/FormTime";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import useApiIncidencia from "../../../api/administracion/useApiIncidencia";
import FormRadioButtonList from "../../../shared/forms/FormFields/FormRadioButtonList";
import FormAutocomplete from "../../../shared/forms/FormFields/FormAutocomplete";

const General = ({value, onChange, onValidate, puedeEditar}) => {
    const api = useApiIncidencia();

    const handleCartaPorte = async (values, onParentChange) => {
        if (values?.cartaPorte === null) {
            onParentChange(values);
            return;
        }

        if (values?.cartaPorte < 1000) {
            onParentChange(values);
            return;
        }

        const result = await api.obtenerViaje(values?.cartaPorte);
        const operadorId = result?.operadorId;
        const unidadId = result?.unidadId;
        const cajaId = result?.cajaId;
        const coordinadorId = result?.coordinadorId;
        const empresaId = result?.empresaId;
        const tipoEmpleadoId = result?.tipoEmpleadoId;

        onParentChange({...values, operadorId, unidadId, cajaId, coordinadorId, empresaId, tipoEmpleadoId});
    };

    return (<Form columns={2} values={value} onChange={onChange} onValidate={onValidate}
                  formDisabled={(value?.id > 0 && value?.estatus !== constantes.estatusIncidencias.Creada) || !puedeEditar}>
        <Field label={'Folio'} field={'folio'} render={FormTextBox} disabled={true}/>
        <Field field={"solicitante"} label={"Solicitante"} required={true} cols={1}
               render={({onParentChange, parent, ...props}) =>
                   <FormAutocomplete source="empleados"
                                     disabled={value?.id > 0}
                                     params={{operadores: false}} {...props} />}/>
        <Field label={'Carta Porte (Opcional)'} field={'cartaPorte'}
               render={({onParentChange, parent, ...props}) =>
                   <FormInputNumber {...props} min={1}
                                    useGrouping={false}
                                    onChange={(cartaPorte) => handleCartaPorte({
                                        ...parent, cartaPorte
                                    }, onParentChange)}/>}/>
        <Field label={"Unidad de Negocio"} field={"unidadDeNegocioId"}  source="unidadesNegocio" render={FormDropDown}
               required={true}/>
        <Field label={"Departamento"} field={"departamentoId"} source={"departamentosIncidencia"} render={FormDropDown}
               required={true}/>
        <Field label={"Ubicación"} field={"ubicacionId"} source={"general/PatioUbicacion"} render={FormDropDown}
               required={true}/>
        <Field field={'operadorId'} label={'Operador'}
               render={({onParentChange, parent, ...props}) => <FormDropDown {...props} required={true}
                                                                             source="empleados"
                                                                             params={{
                                                                                 puestoIds: [constantes.puestos.operador],
                                                                                 todasLasEmpresas: true
                                                                             }}
                                                                             onChange={(operadorId, item) => onParentChange({
                                                                                 operadorId,
                                                                                 coordinadorId: item?.jefeId,
                                                                                 tipoEmpleadoId: item?.tipoEmpleadoId
                                                                             })}/>
               }
        />
        <Field field={'coordinadorId'} label={'Coordinador  (Opcional)'} source="empleados" render={FormDropDown}
               params={{puestoIds: [constantes.puestosDeTrafico], todasLasEmpresas: true}}/>
        <Field field={"tipoEmpleadoId"} label={"Tipo de Operación"} source={"tiposEmpleadoIncidencia"} render={FormDropDown}
               required={true} disabled={value?.id > 0}/>
        <Field field={'unidadId'} label={'Unidad'} source="unidades" render={FormDropDown} required={true}
               params={{todasLasEmpresas: true}}/>
        <Field field={'cajaId'} label={'Caja  (Opcional)'} source="unidadesCajas" render={FormDropDown}
               params={{id: constantes.tiposEquipo.arrastre, todasLasEmpresas: true}}/>
        <Field label={'Lugar y Evento'} field={'lugarEvento'} render={FormTextBox} required={true}/>
        <Field field={'tipoId'} label={'Tipo de Incidencia'}
               render={({onParentChange, parent, ...props}) => <FormDropDown {...props} required={true}
                                                                             source="tipoIncidencias"
                                                                             params={{departamentoId: parent?.departamentoId}}/>
               }
               required={true}/>
        <Field field={'subTipoId'} label={'SubTipo de Incidencia'}
               render={({onParentChange, parent, ...props}) => <FormDropDown {...props} required={true}
                                                                             source="subTipoIncidencias"
                                                                             params={{tipoId: parent?.tipoId}}/>
               }
        />
        <Field label={'Descripción'} field={'descripcion'} cols={2}
               render={({...props}) => <FormTextArea {...props} maxLength={1000} required={true}/>}/>
        <Field label={'Reporte Aseguradora (Opcional)'} field={'reporteAseguradora'} render={FormTextBox}/>
        <Field field={'EstadoUnidadId'} label={'Estado Unidad (Opcional)'} source="incidenciaEstadoEvento"
               render={FormDropDown}/>
        <Field field={'responsableAfectadoId'} label={'Responsable / Afectado (Opcional)'} source="responsableAfectado"
               render={FormRadioButtonList}/>
        <Field field={'paisId'} label={'Pais'} source="paises" render={FormDropDown} required={true}/>
        <Field field={'estadoId'} label={'Estado'} source="estados"
               render={({onParentChange, parent, ...props}) => (
                   <FormDropDown source="subTipoIncidencias" params={{id: parent?.paisId}}
                                 required={true} {...props} />)}/>
        <Field field={'municipioId'} label={'Municipio'} source="municipios"
               render={({onParentChange, parent, ...props}) => (
                   <FormDropDown source="subTipoIncidencias" params={{id: parent?.estadoId}} {...props} />)}/>
        <Field field={"atendio"} label={"Atendio"} required={true} cols={1}
               render={({onParentChange, parent, ...props}) =>
                   <FormAutocomplete source="empleados"
                                     params={{operadores: false,todasLasEmpresas: true}} {...props} />}/>
        {/*<Field label={'Atendio'} field={'atendio'} render={FormTextBox} required={true}/>*/}
        <Field label={'Fecha Incidencia'} field={'fechaIncidencia'} render={FormCalendar} required={true}/>
        <Field label={'Hora Incidencia'} field={'horaIncidencia'} render={FormTime} required={true}/>
        <Field label={'Longitud (Opcional)'} field={'longitud'} maxFractionDigits={10}
               render={({...props}) => <FormInputNumber {...props} required={false}/>}/>
        <Field label={'Latitud (Opcional)'} field={'latitud'} maxFractionDigits={10}
               render={({...props}) => <FormInputNumber {...props} required={false}/>}/>
        <Field label={'Evidencia'} field={'evidencia'} render={FormClipboardImage} required={false}/>
    </Form>);
};

export default General;
