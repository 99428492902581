/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-mixed-operators */
import {Button} from "primereact/button";
import React, {useEffect, useReducer, useState, useImperativeHandle} from "react";
import useApiViaje from "../../../api/operaciones/useApiViajes";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import constantes from "../../../catalogos/constantes";
import constantesOperacion from "../../constantes";
import {TabPanel, TabView} from "primereact/tabview";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldOverlayPanel from "../../../shared/controls/fieldOverlayPanel";
import {PanelConvenios, PanelDropdown} from "./paneles";
import useApiCotizaciones from "../../../api/comercial/cotizaciones";
import {Ruta} from "../../comercial/cotizaciones/Rutas/ruta";
import RutasViaje from "./rutas";
import CostosAnticipos from "./costosAnticipos";
import Articulos from "./articulos";
import RutasSegmentos from "./rutasSegmentos";
import {produce} from "immer";
import useApiBanxico from "../../../api/administracion/useApiBanxico";
import CancelarViaje from "./cancelar";
import {
    viajeValidacionEsquema,
    agregarAnticipos,
    validacionTimbrarExpo, validacionTimbrarImpo, validaTimbrar
} from "./viajeValidacionEsquema";
import moment from "moment";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import PdfViewer from "../../../shared/componentes/pdfViewer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import alerts from "../../../shared/alerts";
import dateFormat from "dateformat";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import CfdiRelacionados from "./cfdiRelacionados"
import FacturacionLear from "./facturacionLear";
import useSeguridad from "../../../Seguridad/useSeguridad";
import {Dialog} from "primereact/dialog";
import {useEmpresa} from "../../../layout/EmpresaProvider";
import CancelarCartaPorte from "./CancelarCartaPorte";
import articuloGenerico from "../facturacion/articuloGenerico";
import Documentos from "./documentos";

const DetalleViaje = ({viajeId, onSave, close, ref, soloLectura, facturacionMultiempresa}) => {
    const api = useApiViaje();
    const apiCotizaciones = useApiCotizaciones();
    const viajeVacioId = constantesOperacion.tipoProgramacionViaje.vacio;
    const estatus = constantesOperacion.estatusViajes;
    const seguridad = useSeguridad();
    const permisoSuperUserGuardado = seguridad.tieneAcceso(constantes.opciones.SuperUserGuardado);
    const {empresa} = useEmpresa();
    const valorInicial = {
        id: 0,
        tipoViajeId: constantesOperacion.tipoProgramacionViaje.convenio,
        esEditable: true,
        rutasEditables: true,
        casetas: [],
        anticipos: [],
        articulos: [],
        conceptos: [],
        puedeDesegmentar: true,
        moneda: empresa?.moneda?.codigo
    };
    const apiBanco = useApiBanxico();
    const [impoExpo] = useState([
        {id: false, nombre: "Impo"},
        {id: true, nombre: "Expo"},
    ]);

    const defaultEntity = () => ({entity: valorInicial});

    const [{entity, errors}, dispatch] = useReducer(formReducer, {}, defaultEntity);
    const [rutas, setRutas] = useState([]);
    const [convenios, setConvenios] = useState([]);
    const [cancelarVisible, setCancearVisible] = useState(false);
    const [pdfCartaPorteVisible, setPdfCartaPorteVisible] = useState(false);
    const [editarComentarios, setEditarComentarios] = useState(false);
    const [editarDCCP, setEditarDCCP] = useState(false);
    const [esRequeridoComentarios, setEsRequeridoComentarios] = useState(false);
    const [requeridoComentarios, setRequeridoComentarios] = useState(false);
    const [anticipoInicial, setAnticipoInicial] = useState(false);
    const [pdfFacturaTimbradaVisible, setPdfFacturaTimbradaVisible] = useState(false);
    const [errorFuel, setErrorFuel] = useState(false);
    const [tarifaConvenioExpeditado, setTarifaConvenioExpeditado] = useState(0);
    const [cambiarTarifa, setCambiarTarifa] = useState(false);
    const [cancelarCP, setCancelarCP] = useState(false);
    const [incidencia, setIncidencia] = useState({});
    const [verIncidencia, setVerIncidencia] = useState(false);

    const formHandler = new FormHandler({
        validationSchema: viajeValidacionEsquema,
        api,
        dispatch,
    });

    const formHandlerAnticipo = new FormHandler({
        validationSchema: agregarAnticipos,
        api,
        dispatch
    });

    useEffect(() => {
        let timer = setTimeout(null, 0);

        async function cargar() {
            const result = facturacionMultiempresa ? await api.obtenerMultiempresa(viajeId) : await api.obtener(viajeId);

            if (facturacionMultiempresa && result.cliente.facturacionPropia && result.articulos.length === 0) {
                result.articulos = [articuloGenerico];
            }

            setAnticipoInicial(result.anticipos?.length ?? 0);
            result.rutas.forEach((e, index) => {
                e.esViajeVacio = result.tipoViajeId === viajeVacioId;
                e.esUltimaRuta = index === result.rutas.length - 1;
            });

            //Obtiene las rutas para llenar el dropdown de Ruta
            obtenerRutas(null, "", result).then();
            dispatch({type: "update", value: result});
        }

        if (viajeId > 0) {
            cargar().then();
        }

        return () => clearTimeout(timer);
    }, []);

    useImperativeHandle(ref, () => ({
        saveConcept() {
            guardarConceptos();
        }
    }));

    const obtenerTramosRutas = (rutas) => {
        const indexDestino = rutas.findIndex(e => e.stopNumber === constantes.tipoRuta.destino);
        const totalRutas = rutas.length;
        let tramo = 1;
        let stopNumber = 1;
        rutas = produce(rutas, draft => {
                rutas.forEach((e, index) => {
                    draft[index].tramo = 0;
                    draft[index].comision = e.comision === 0 ? 0 : e.comision;
                    //No se genera tramo a la última ruta, ni al destino, a menos de que sea un viaje nuevo y sea redondo
                    if (index < totalRutas - 1 && (index !== indexDestino || entity.id === 0 && entity.esViajeRedondo)) {
                        draft[index].tramo = tramo;
                        tramo++;
                    }

                    if (e.stopNumber !== 0 && e.stopNumber < 100) {
                        draft[index].nombre = "STOP " + stopNumber;
                        stopNumber++;
                    }
                });
            }
        );

        return rutas;
    };
    const permitirEditarComentarios = () => {
        setEditarComentarios(true);
    }
    const permitirEditarCCP = () => {
        setEditarDCCP(true);
    }
    const validaRequeridoComentarios = () => {
        const defineValor = (esRequeridoComentarios || (entity.comentarioRequerido == null ? false : entity.comentarioRequerido))
            && (entity.comentarios === '' || entity.comentarios == null);
        setRequeridoComentarios(defineValor);
        return requeridoComentarios;
    }
    const actualizarComentarios = async () => {
        await api.guardarComentarios(entity.id, {comentariosInternos: entity.comentariosInternos ?? ''});
        await api.obtener(entity.id);

        setEditarComentarios(false);
    }

    const actualizarDescripcion = async () => {
        await api.guardarDescripcion(entity.id, {comentarios: entity.comentarios ?? ''});
        await api.obtener(entity.id);
        setEditarDCCP(false);
    }

    const actualizar = (value, propertyName, obtnerTramos = false) => {
        if (propertyName === "rutas" && obtnerTramos) {
            value = obtenerTramosRutas(value);
        }
        dispatch({type: 'update', value: value, name: propertyName});

        if (propertyName === "cliente" && value != null) {
            setEsRequeridoComentarios(value.comentarioRequerido);
            dispatch({type: 'update', value: value.incluirIdViajeExterno, name: "incluirIdViajeExterno"});
            dispatch({type: 'update', value: value.comentarioRequerido, name: "comentarioRequerido"});
        }

        if (propertyName === "cliente" || propertyName === "tipoUnidad") {
            dispatch({type: 'update', value: null, name: "ruta"});
        }

        limpiarCampos(value, propertyName);
        obtenerRutas(value, propertyName).then();
        obtenerConvenios(value, propertyName, true).then();
        if (propertyName === "comentariosInternos") {
            validaRequeridoComentarios();
        }
    };

    //Obtiene las rutas cuando cambia el cliente o el tipo de unidad, o si result (es un viaje ya creado) trae valor
    const obtenerRutas = async (value, propertyName, result) => {
        const viaje = result ?? entity;
        const clienteId = propertyName === "cliente" ? value?.id : viaje.cliente?.id;
        const tipoUnidadId = propertyName === "tipoUnidad" ? value?.id : viaje.tipoUnidad?.id;
        if ((propertyName === "cliente" || propertyName === "tipoUnidad" || result != null) &&
            clienteId != null && tipoUnidadId != null) {

            let listrutas = [];
            if (facturacionMultiempresa) {
                listrutas.push({
                    id: viaje?.ruta?.id,
                    nombre: viaje?.ruta?.nombre,
                    rutaViajeIds: viaje?.rutas?.map(r => r.id).toString()
                });
                setRutas(listrutas);
            } else {
                listrutas = await api.obtenerRutas({tipoViajeId: viaje.tipoViajeId, clienteId, tipoUnidadId});
                setRutas(listrutas);
            }

            //Si el viaje ya existe (es edición) se buscan los convenios ligados a la ruta de ese viaje
            if (result != null && result.ruta != null) {
                const ruta = listrutas.find(e => e.id === result.ruta.id);
                if (ruta) {
                    const nuevosConvenios = await api.obtenerConvenios(ruta.rutaViajeIds, result.tipoViajeId, false);
                    setConvenios(nuevosConvenios);
                }
            }
        }
    };

    const obtenerConvenios = async (value, propertyName, actualizarCampos) => {
        if (propertyName === "ruta") {
            const nuevosConvenios = value != null ? await api.obtenerConvenios(value.rutaViajeIds, entity.tipoViajeId) : [];
            setConvenios(nuevosConvenios);

            if (actualizarCampos) {
                dispatch({type: 'update', value: null, name: "convenio"});
                dispatch({type: 'update', value: [], name: "rutas"});
            }
        }
    };

    //Cuando se cambia el tipo de viaje se limpian todos los campos, y si es un viaje vacío, se agregan 2 rutas
    const limpiarCampos = (value, propertyName) => {
        if (propertyName === 'tipoViajeId') {
            let rutas = [];
            if (value === viajeVacioId) {
                rutas = [
                    {
                        id: 1,
                        nombre: 'ORIGEN',
                        esViajeVacio: true,
                        esCarga: false,
                        esDescarga: false,
                        esOrigen: true,
                        stopNumber: constantes.tipoRuta.origen
                    },
                    {
                        id: 2,
                        nombre: 'DESTINO',
                        esViajeVacio: true,
                        esCarga: false,
                        esDescarga: false,
                        esDestino: true,
                        stopNumber: constantes.tipoRuta.destino
                    }
                ]
            }

            dispatch({
                type: 'update',
                value: {...valorInicial, id: viajeId, tipoViajeId: value, rutas}
            });
        }
    };

    const obtenerCasetas = async (viaje, casetasExcluidas) => {
        const casetas = await apiCotizaciones.detalleCasetas(viaje.ids, casetasExcluidas ?? '');
        actualizar(casetas, "casetas");
    };

    const actualizarConvenio = (convenio) => {
        if (convenio.viajesTotales > 0 && convenio.viajesDisponibles == 0) {
            alerts.informacion('No hay viajes disponibles para este convenio con esta orden de compra, favor de validar.');
            return;
        }

        let nombre = "";
        const rutas = [];
        const esViajeRedondo = convenio != null && convenio.tipoViajeId === constantesOperacion.tipoViaje.redondo;

        convenio.paradas.forEach((e, i) => {
            nombre += `${e.planta}${i + 1 < convenio.paradas.length ? ' - ' : ''}`;
            const esOrigen = e.stopNumber === constantes.tipoRuta.origen;
            const esDestino = e.stopNumber === constantes.tipoRuta.destino;
            const esRetorno = e.stopNumber === constantes.tipoRuta.retorno;
            const nombreRuta = esOrigen ? "ORIGEN" : esDestino ? "DESTINO" : esRetorno ? "RETORNO" : "STOP " + i;
            const esUltimaRuta = i === convenio.paradas.length - 1;

            rutas.push({
                ...e,
                nombre: nombreRuta,
                esOrigen,
                esDestino,
                esRetorno,
                esUltimaRuta,
                esCarga: false,
                esDescarga: false
            })
        });

        convenio.nombre = nombre;

        actualizar({id: convenio.id, tipoViajeId: convenio.tipoViajeId, nombre}, "convenio");
        actualizar(esViajeRedondo, "esViajeRedondo");
        actualizar(false, "esSegmentado");
        actualizar([...rutas], "rutas");
        actualizar(convenio.aplicaFuel, "aplicaFuel");
        actualizar(convenio.conceptos, "conceptos");
        actualizar(convenio.ordenCompra, "ordenCompra");
        actualizar(convenio.viajesTotales, "viajesTotales");
        actualizar(convenio.viajesRealizados, "viajesRealizados");
        actualizar(convenio.viajesDisponibles, "viajesDisponibles");
        actualizar(convenio.internacional, "internacional");
        actualizar(convenio.impoExpo, "impoExpo");
        actualizar(convenio.tarifa.comision, "comision");

        if (convenio.tarifa.facturacionUsd) {
            setTarifaConvenioExpeditado(convenio.tarifa.montoFacturacionUsd);
        } else {
            setTarifaConvenioExpeditado(convenio.tarifa.FacturacionMxn);
        }

        actualizar(tarifaConvenioExpeditado, "tarifaConvenioExpeditado");

        if (convenio.ruta != null) {
            const convenioRuta = new Ruta(convenio.ruta);
            obtenerCasetas(convenioRuta.viaje, convenioRuta.costo.casetasExcluidas).then();
        }
    };

    const detalleInvalido = () => {
        return !!errors && (errors.cliente || errors.tipoUnidad || errors.ruta || errors.convenio
            || errors.idViaje || errors.idViajeRetorno || errors.rutas || requeridoComentarios || errorFuel)
    };

    function agregarTramoRuta(indexTramoActual = 0) {
        const indexDestino = entity.rutas.findIndex(e => e.stopNumber === constantes.tipoRuta.destino);
        const indexNuevoStop = indexTramoActual > indexDestino ? entity.rutas.length - 1 : indexDestino;
        //Se generá un id temporal para poder utilizar el drag and drop
        const id = Math.max(...entity.rutas.map(m => m.id)) + 1;

        const nuevoTramo = {
            id,
            esNuevoTramo: true,
            stopNumber: indexNuevoStop,
            esCarga: false,
            esDescarga: false,
            esUltimaRuta: false,
            comision: 0
        };

        let newValue = produce(entity.rutas, draft => {
                draft.splice(indexNuevoStop, 0, nuevoTramo);
            }
        );

        actualizar(newValue, 'rutas', true);
    }

    const xmlTimbrado = async () => {
        var cartaporteTimbrada = await api.cartaporteXml(entity.id, facturacionMultiempresa, empresa?.id ?? 0);
        let blob = new Blob([cartaporteTimbrada], {type: 'text/xml'});
        let url = URL.createObjectURL(blob);
        //window.open(url);
        //URL.revokeObjectURL(url);
        const now = new Date();
        const fecha = dateFormat(now, "yyyyMMddhhmmss");
        let dLink = document.createElement('a');
        dLink.href = url;
        dLink.setAttribute('download', entity.cartaPorte + '_' + fecha + '.xml');
        dLink.click();
    }

    const enviarFactura = async () => {
        await api.sendEmail(entity.id);
    }
    const cancelarTimbrado = () => {
        // if (await alerts.preguntarSiNo('¿Desea cancelar el timbrado de la carta porte?') === false) {
        //     return;
        // }
        //
        // await api.cancelarTimbrado(entity.id);
        setCancelarCP(true);
    }
    const pdfTimbrado = async () => {
        await api.cartaportePdf(viajeId, entity.cartaPorte, facturacionMultiempresa, empresa?.id ?? 0);
        setPdfFacturaTimbradaVisible(false);
    };

    const segmentar = (esSegmentado) => {
        const indexDestino = entity.rutas.findIndex(e => e.stopNumber === constantes.tipoRuta.destino);
        const agregarTramo = esSegmentado && indexDestino === 1; //Si el index es 1 no hay stops entre origen y destino

        if (agregarTramo) {
            agregarTramoRuta();
        } else if (!esSegmentado) {
            const newValue = entity.rutas.filter(e => !e.esNuevoTramo);
            actualizar(newValue, 'rutas');
        }

        actualizar(esSegmentado, 'esSegmentado');
    };

    const timbrar = async () => {
        if ((entity.direccion?.id ?? 0) === 0 && !facturacionMultiempresa) {
            alerts.informacion('Seleccionar la dirección del cliente.');
            return;
        }

        if (entity?.articulos.some(a => (a.cantidad ?? 0) === 0)) {
            alerts.informacion("La cantidad de uno o mas articulos debe ser mayor a 0.");
            return;
        }

        if (entity?.articulos.some(a => (a.peso ?? 0) === 0)) {
            alerts.informacion("El peso de uno o mas articulos debe ser mayor a 0.");
            return;
        }

        if (entity?.articulos.some(a => (a.unidadConClave?.id === null || (a.unidadConClave?.id ?? 0) === 0))) {
            alerts.informacion("Uno o mas aticulos no tienen seleccionada la unidad de medida.");
            return;
        }

        if (entity?.internacional) {
            if (entity.articulos.some(a => !a.fraccionValida)) {
                alerts.informacion('Algunas Fracciones Arancelarias no estan registradas en el catalogo del SAT. No es posible timbrar. Favor de validar la información.');
                return;
            }

            const valida = entity?.impoExpo ? await validacionTimbrarExpo.isValid(entity) : await validacionTimbrarImpo.isValid(entity);
            if (!valida) {
                alerts.informacion('Es necesario capturar la fraccion arancelaria y el pedimento de los articulos en viajes Impo/Expo.');
                return;
            }
        } else {
            const valida = await validaTimbrar.isValid(entity);
            if (!valida) {
                alerts.informacion('Algunos articulos tienen la información incompleta.');
                return;
            }
        }

        setErrorFuel(false);
        if (entity.aplicaFuel) {
            if (entity.fuel <= 0) {
                setErrorFuel(true);
                alerts.informacion('El campo Combustible(Fuel) es requerido para timbrar con el convenio seleccionado.');
                return;
            }
        }

        if (validaAddenda(entity.addenda)) {
            alerts.aviso("Favor de capturar los valores de la Addenda.");
            return;
        }

        if (entity.articulos.some(a => !a.enSAT)) {
            alerts.informacion('Algunos articulos no estan registrados en el catalogo del SAT. No es posible timbrar. Favor de validar la información.');
            return;
        }

        if (entity?.rutas.filter(r => r.esOrigenDestino).length !== 2) {
            alerts.informacion('Es necesario seleccionar el origen y el destino en las rutas del viaje.');
            return;
        }

        if (entity?.canceladoPrevio) {
            const confirmar = await alerts.preguntarSiNoAdvertencia("Esta carta porte ya fue cancelada anteriormente por el motivo de: sin documentos liberados, deseas continuar con el timbrado?");
            if (!confirmar)
                return;
        }

        var cartaporteTimbrada = facturacionMultiempresa ? await api.timbrarMultiempresa(entity?.id, entity) : await api.timbrar(entity.id, entity);
        if (cartaporteTimbrada.factura_no > 0) {
            alerts.informacion("Se timbro la carta porte con la factura: "
                + cartaporteTimbrada.process_timbrado.serie
                + cartaporteTimbrada.process_timbrado.folio
            );
        } else {
            if (cartaporteTimbrada.messages?.length > 0) {
                alerts.aviso(cartaporteTimbrada.messages.toString());
            } else {
                alerts.aviso("Ocurrio un error al timbrar");
            }
        }
        onSave();
    }

    const validaAddenda = (addenda) => {
        if (entity.tieneAddenda) {
            var resultado = false;
            if (entity.addendaId === constantes.addendas.soriana) {
                if (addenda.numeroProveedor === ""
                    || addenda.cantidadUnidadCompra === ""
                    || addenda.folioPedido === ""
                    || addenda.fechaEntregaMercancia === ""
                    || addenda.tienda === ""
                    || addenda.numeroEntregaMercancia === ""
                    || addenda.folioNotaEntrada === "") {
                    resultado = true;
                }
            } else if (entity.addendaId === constantes.addendas.mabe) {
                if (addenda.ordenCompra === ""
                    || addenda.plantaEntrega === ""
                    || addenda.plantaEntregaCP === ""
                    || addenda.plantaEntregaCalle === ""
                    || addenda.plantaEntregaNoExterior === ""
                    || addenda.plantaEntregaNoInterior === ""
                    || addenda.claveDeRuta === "") {
                    resultado = true;
                }
            }
            return resultado;
        } else {
            return false;
        }
    }

    const guardar = async () => {
        const comisionInvalida = entity.esSegmentado && entity.rutas?.some(e => e.tramo > 0 && e.comision == null);

        if (comisionInvalida) {
            const rutas = produce(entity.rutas, (draft) => {
                entity.rutas.forEach((e, i) => {
                    if (!e.esUltimaRuta && e.comision == null) {
                        draft[i].errorComision = true;
                    }
                })
            });

            actualizar(rutas, 'rutas');
            return
        }
        // if (validaRequeridoComentarios())
        // {
        //     return;
        // }

        const response = await formHandler.save(entity);

        if (formHandler.valdation.result) {
            onSave();
            api.actualizarDistanciaGeocercas(response.id);
        }
    };

    const descargarCartaPorte = async () => {
        await api.descargar(viajeId, entity.cartaPorte);
        setPdfCartaPorteVisible(false);
    };
    const guardarConceptos = async () => {
        let id = entity.id ?? 0;
        if (id > 0 && entity?.anticipos.length !== anticipoInicial) {
            const seguir = await formHandlerAnticipo.validate(entity);
            if (seguir.result) {
                await api.addAnticipo(entity.id, entity?.anticipos);
                onSave();
            } else {
                return;
            }
        } else {
            close();
        }
    };

    const timbrarMultiempresa = async () => {
       if (entity?.facturacionUsd) {
           const tipocambio = await apiBanco.tipocambio();
           const nuevaTarifa = (entity?.tarifaConvenioExpeditado ?? 0) * (tipocambio ?? 0) * entity.porcentajeMultiEmpresa;
           actualizar(nuevaTarifa, 'facturacion');
       } else {
           actualizar(entity?.tarifaConvenioExpeditado * entity.porcentajeMultiEmpresa, 'facturacion');
       }
       setCambiarTarifa(true);
    };

    const agregarIncidencia = async () => {
        await api.incidenciaTimbrado(viajeId, incidencia);
        setVerIncidencia(false);
        onSave();
    };
    const footerRenderModal = () => (
        <div>
            <Button label="Cancelar" onClick={() => setCambiarTarifa(false)} className="p-button-outlined"/>
            <Button label="Timbrar" onClick={timbrar} autoFocus/>
        </div>
    );

    const footerRenderModalIncidencia = () => (
        <div>
            <Button label="Cancelar" onClick={() => setVerIncidencia(false)} className="p-button-outlined"/>
            <Button label="Confirmar" onClick={agregarIncidencia} autoFocus/>
        </div>
    );

    return (
        <>
            <div className="col-12">
                <div className="form-container full-height">
                    <h6 className="col-8"><b>Carta Porte: </b> {entity?.cartaPorte}</h6>
                    <TabView activeIndex={0}>
                        <TabPanel header="VIAJE"
                                  rightIcon={detalleInvalido() ? 'pi pi-exclamation-circle pl-1' : ''}>
                            <div className="form-row">
                                <h5 className="col-12">Detalle del Viaje {entity?.cartaPorte}</h5>
                                <div className="form-row col-12">
                                    {entity.timbrado ?
                                        <>
                                            <div className="col-3">
                                                <span><u><b>Factura:</b> {entity.numeroFactura} </u></span>
                                            </div>
                                            <div className="col-1">
                                                <a className="p-button btn-sm" onClick={xmlTimbrado}
                                                   target="_blank">XML</a>
                                            </div>
                                            <div className="col-1">
                                                <a className="p-button btn-sm"
                                                   onClick={() => setPdfFacturaTimbradaVisible(true)}>PDF</a>
                                                <PdfViewer header="Carta Porte"
                                                           urlApi={`operaciones/viajes/programacion/${viajeId}/cartaportePdf/${facturacionMultiempresa}/${empresa?.id ?? 0}`}
                                                           descargar={pdfTimbrado} visible={pdfFacturaTimbradaVisible}
                                                           hide={() => setPdfFacturaTimbradaVisible(false)}/>
                                            </div>
                                            <div className="col-4">
                                                <a className="p-button btn-sm" onClick={enviarFactura}>Enviar por
                                                    Correo</a>
                                            </div>
                                            <div className="col-3">
                                                {entity.tienePermisoCancelar && (!soloLectura || facturacionMultiempresa) && entity.timbrado &&
                                                    <Button label="Cancelar carta porte"
                                                            className="p-button-outlined p-button-danger" type="button"
                                                            onClick={cancelarTimbrado}/>
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            {entity?.canceladoPrevio &&
                                                <>
                                                    <span style={{
                                                        marginRight: '3px',
                                                        fontSize: '1.15em',
                                                        fontWeight: 'bold',
                                                        color: 'red'
                                                    }}>Esta carta porte ya fue cancelada anteriormente por el motivo de: sin documentos liberados.</span>
                                                </>
                                            }
                                        </>
                                    }
                                    {entity.errorAlTimbrar &&
                                        <>
                                            <span>Ocurrio un error al timbrar la carta porte revisar el historial</span>
                                        </>
                                    }
                                </div>

                                <div className="form-group col-12">
                                    <FieldRadioButtonList name="tipoViajeId" source="tiposProgramacionViaje"
                                                          valueIsObject={false}
                                                          disabled={(!entity.esEditable || soloLectura)}
                                                          value={entity.tipoViajeId} onChange={actualizar}/>
                                </div>

                                {entity.tipoViajeId !== viajeVacioId &&
                                    <>
                                        <FieldAutoComplete name="cliente" label="Cliente" source="clientes"
                                                           error={errors?.cliente}
                                                           disabled={!entity.esEditable || soloLectura}
                                                           colMd={6} value={entity.cliente} onChange={actualizar}
                                                           params={{soloActivos: true}}/>

                                        <FieldDropDown name="tipoUnidad" label="Tipo Unidad"
                                                       source="tiposUnidadFlotilla"
                                                       colMd={6} disabled={!entity.esEditable || soloLectura}
                                                       error={errors?.tipoUnidad} value={entity.tipoUnidad}
                                                       params={{id: constantes.tiposEquipo.motriz}}
                                                       onChange={actualizar}/>


                                        <FieldOverlayPanel name="ruta" value={entity.ruta} label="Ruta"
                                                           className="col-9 mb-3"
                                                           error={errors?.ruta} onChange={actualizar}
                                                           disabled={(!entity.esEditable && !entity.programado) || soloLectura}>
                                            <PanelDropdown options={rutas}/>
                                        </FieldOverlayPanel>
                                        {entity.aplicaFuel && !facturacionMultiempresa &&
                                            <FieldInputNumber name="fuel" label="Combustible(Fuel)" value={entity.fuel}
                                                              colMd={3}
                                                              disabled={(!entity.esEditable && entity.timbrado) || soloLectura}
                                                              fractionDigits={2} error={errorFuel}
                                                              onChange={actualizar}/>
                                        }

                                        <FieldOverlayPanel value={entity.convenio} label="Convenio" className="col-9"
                                                           error={errors?.convenio} onChange={actualizarConvenio}
                                                           disabled={(!entity.esEditable && !entity.programado) || soloLectura}
                                                           width={convenios.some(e => e.paradas.length > 2) ? '150%' : '98%'}>
                                            <PanelConvenios convenios={convenios}/>
                                        </FieldOverlayPanel>

                                        <FieldTextBox name="noCotizacion" label="Cotización" colMd={1}
                                                      value={entity.noCotizacion}
                                                      onChange={actualizar} errors={errors}
                                                      disabled={true}/>

                                        <div className="form-group col-2 mt-2 checkbox-container">
                                            <FieldCheckBox name="esSegmentado" label="Segmentado"
                                                           value={entity.esSegmentado}
                                                           errors={errors} onChange={segmentar}
                                                           disabled={(!entity.rutasEditables || !entity.puedeDesegmentar || !entity.convenio) || soloLectura}/>
                                        </div>
                                        {entity?.ordenCompra !== null && <>
                                            <div className="form-group col-6 mt-2">
                                                <div>
                                                    Orden de compra: <span
                                                    className="bold">{entity?.ordenCompra?.ordenCompra}</span>
                                                </div>
                                            </div>
                                            <div className="form-group col-6 mt-2">
                                                <div>
                                                    Viajes disponibles: <span
                                                    className="bold">{entity?.ordenCompra?.viajesDisponibles}</span>
                                                </div>
                                            </div>
                                        </>
                                        }

                                        {entity.incluirIdViajeExterno &&
                                            <>
                                                <FieldTextBox name="idViaje" label="ID Viaje" colMd={3}
                                                              value={entity.idViaje}
                                                              onChange={actualizar} errors={errors}
                                                              disabled={(!entity.esEditable && !entity.programado) || soloLectura}/>

                                                {entity.esViajeRedondo &&
                                                    <FieldTextBox name="idViajeRetorno" label="ID Viaje (Retorno)"
                                                                  colMd={3}
                                                                  value={entity.idViajeRetorno} onChange={actualizar}
                                                                  errors={errors}
                                                                  disabled={(!entity.esEditable && !entity.programado) || soloLectura}/>
                                                }
                                            </>
                                        }
                                        <FieldTextBox name={'codigoRuta'} label={'Código Ruta'} colMd={3}
                                                      value={entity?.codigoRuta}
                                                      onChange={actualizar} errors={errors} disabled={true}/>

                                    </>
                                }
                                {entity?.internacional &&
                                    <>
                                        <FieldTextBox name={"taxId"} label={"Tax ID"} colMd={3}
                                                      value={entity?.taxId} onChange={actualizar} errors={errors}
                                                      disabled={true}/>
                                        <div className={`form-group col-md-2 col-sm-4 radiobutonlist-container`}>
                                            <FieldRadioButtonList
                                                options={impoExpo}
                                                name="impoExpo"
                                                colMd={2}
                                                disabled={true}
                                                value={entity?.impoExpo}
                                                onChange={actualizar}/>
                                        </div>
                                    </>
                                }
                            </div>

                            {entity.esSegmentado ?
                                <TabView className="tab-secondary" activeIndex={0}>
                                    <TabPanel header="Ruta Cliente"
                                              rightIcon={errors.rutas ? 'pi pi-exclamation-circle pl-1' : ''}>
                                        <RutasViaje viaje={entity} errors={errors} actualizar={actualizar}
                                                    soloLectura={soloLectura}/>
                                    </TabPanel>

                                    <TabPanel header="Segmentos"
                                              rightIcon={entity.rutas?.some(e => e.errorComision) ? 'pi pi-exclamation-circle pl-1' : ''}>
                                        <RutasSegmentos viaje={entity} actualizar={actualizar} errors={errors}
                                                        agregarTramoRuta={agregarTramoRuta} soloLectura={soloLectura}/>
                                    </TabPanel>
                                </TabView>
                                :
                                <RutasViaje viaje={entity} errors={errors} actualizar={actualizar}
                                            soloLectura={soloLectura}/>
                            }

                            <div className="form-row">
                                <div className="form-group col-11">
                                    <FieldTextArea name="comentarios" label="Descripción Complemento Carta Porte"
                                                   value={entity.comentarios} colMd={12}
                                                   rows={2} className="mb-0"
                                                   maxLength={1000}
                                                   onChange={actualizar}
                                                   disabled={(!entity.rutasEditables && !editarDCCP) || soloLectura}/>

                                </div>
                                <div className="form-group col-1">
                                    <div className="d-flex align-items-center">
                                        <div className="table-button flex-grow-1">
                                            {(editarDCCP && !soloLectura) &&
                                                <ButtonIcon title="Guardar" className="grid-action"
                                                            iconName={'AiOutlineSave'}
                                                            onClick={actualizarDescripcion}/>
                                            }
                                            {(!editarDCCP && !soloLectura) &&
                                                <ButtonIcon title="Editar Comentarios" className="grid-action"
                                                            iconName={'FaRegEdit'}
                                                            onClick={permitirEditarCCP}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-11">
                                    <FieldTextArea name="comentariosInternos" label="Comentarios Internos"
                                                   value={entity.comentariosInternos} colMd={12}
                                                   onChange={actualizar} rows={2} className="mb-0"
                                                   maxLength={1000}
                                                   disabled={(!entity.rutasEditables && !editarComentarios) || soloLectura}/>
                                </div>
                                <div className="form-group col-1">
                                    <div className="d-flex align-items-center">
                                        <div className="table-button flex-grow-1">
                                            {editarComentarios && !soloLectura &&
                                                <ButtonIcon title="Guardar" className="grid-action"
                                                            iconName={'AiOutlineSave'}
                                                            onClick={actualizarComentarios}/>
                                            }
                                            {!editarComentarios && !soloLectura &&
                                                <ButtonIcon title="Editar Comentarios" className="grid-action"
                                                            iconName={'FaRegEdit'}
                                                            onClick={permitirEditarComentarios}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-group col-6"}>
                                    <FieldDropDown name="coordinador" label="Coordinador"
                                                   source="empleados"
                                                   colMd={12} disabled={!entity.rutasEditables || soloLectura}
                                                   error={errors?.coordinador} value={entity.coordinador}
                                                   params={{
                                                       puestoIds: constantes.puestosDeTrafico,
                                                       todasLasEmpresas: true
                                                   }}
                                                   onChange={actualizar}/>
                                </div>
                                <div className={"form-group col-6"}>
                                    <FieldCheckBox name="comentarioEnCartaPorte" label="Mostrar En Carta Porte"
                                                   colMd={12}
                                                   onChange={actualizar} value={entity.comentarioEnCartaPorte}
                                                   className="ml-0"
                                                   disabled={!entity.rutasEditables || soloLectura}/>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel header="COSTOS Y ANTICIPOS"
                                  rightIcon={errors?.anticipos || errors?.comision ? 'pi pi-exclamation-circle pl-1' : ''}>
                            <CostosAnticipos viaje={entity} errors={errors} actualizar={actualizar}
                                             soloLectura={soloLectura}/>
                        </TabPanel>
                        <TabPanel header="ARTICULOS"
                                  rightIcon={errors?.articulos ? 'pi pi-exclamation-circle pl-1' : ''}>
                            <Articulos viaje={entity} errors={errors} actualizar={actualizar}
                                       soloLectura={soloLectura}/>
                        </TabPanel>
                        <TabPanel header="UNIDAD">
                            <table className="table table-sm">
                                <thead className="thead-light">
                                <tr>
                                    <th style={{width: '15%'}}>NUMERO ECONOMICO</th>
                                    <th>SERVICIO SAAS</th>
                                    <th>PERMISO SCT</th>
                                    <th style={{width: '12%'}}>PESO BRUTO</th>
                                    <th>ASEGURADORA</th>
                                    <th>POLIZA</th>
                                    <th>PLACAS</th>
                                    <th>MODELO</th>                                    
                                </tr>
                                </thead>
                                <tbody>

                                {entity?.unidades?.map((item) => {
                                    return (
                                        <tr key={item?.id}>
                                            <td>{item?.numeroEconomico}</td>
                                            <td>{item?.servicioSaas}</td>
                                            <td>{item?.permisoSCT}</td>
                                            <td>{item?.pesoBruto}</td>
                                            <td>{item?.nombreAseguradora}</td>
                                            <td>{item?.polizaSeguro}</td>
                                            <td>{item?.placas}</td>
                                            <td>{item?.modelo}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </TabPanel>
                        <TabPanel header="CFDI Relacionados">
                            <CfdiRelacionados viaje={entity} errors={errors} actualizar={actualizar}
                                              soloLectura={soloLectura}/>
                        </TabPanel>
                        <TabPanel header="FACTURACIÓN LEAR">
                            <FacturacionLear viajeId={entity.id} soloLectura={soloLectura} viaje={entity}
                                             onChange={actualizar} onErrorFuel={setErrorFuel}/>
                        </TabPanel>
                        <TabPanel header="DOCUMENTOS"
                                  rightIcon={errors?.articulos ? 'pi pi-exclamation-circle pl-1' : ''}>
                            <Documentos viaje={entity} errors={errors} actualizar={actualizar}
                                       soloLectura={soloLectura}/>
                        </TabPanel>
                        <TabPanel header="HISTORIAL">
                            <table className="table table-sm">
                                <thead className="thead-light">
                                <tr>
                                    <th style={{width: '15%'}}>FECHA</th>
                                    <th>CAMBIO</th>
                                    <th style={{width: '12%'}}>MOTIVO</th>
                                    <th>COMENTARIO</th>
                                    <th>USUARIO</th>
                                </tr>
                                </thead>
                                <tbody>
                                {entity.logs?.map((item) => {
                                    return (
                                        <tr key={item.id}
                                            style={{color: (item.motivo === "Error" || item.motivo === "Incidencia") ? "#FF0000" : "#212529"}}>
                                            <td>{moment(item.fecha).format('DD/MM/YYYY h:mm a')}</td>
                                            <td>{item.cambio}</td>
                                            <td>{item.motivo}</td>
                                            <td>{item.comentario}</td>
                                            <td>{item.usuario}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </TabPanel>
                    </TabView>
                </div>
            </div>

            <div className="form-footer">
                {entity.cartaPorte > 0 && entity.estatus !== estatus.cancelado &&
                    <div className="flex-grow-1">
                        <Button label='Descargar carta porte' className="p-button-outlined" type="button"
                                onClick={() => {
                                    setPdfCartaPorteVisible(true);
                                    api.cartaPorteLeida(viajeId);
                                }
                                }/>
                    </div>
                }

                <Button label='Salir' className="p-button-outlined" type="button" onClick={close}/>

                {!soloLectura &&
                    <>
                        {entity.puedeCancelar &&
                            <Button label="Cancelar" className="p-button-outlined p-button-danger" type="button"
                                    onClick={() => setCancearVisible(true)}/>
                        }
                        {entity.id > 0 && entity.direccion != null && (entity.articulos?.length > 0 || entity.articulosExcel?.length > 0) && !entity.liquidado &&
                            (empresa.facturacionHabilitada ?? true) &&
                            <>
                                <Button label='Incidencia Timbrado' className="p-button-danger" type="button"
                                        onClick={() => setVerIncidencia(true)}/>
                            </>
                        }

                        {entity.id > 0 && entity.direccion != null && (entity.articulos?.length > 0 || entity.articulosExcel?.length > 0) && !entity.timbrado &&
                            (empresa.facturacionHabilitada ?? true) &&
                            <>
                                <Button label='Timbrar' className="p-button-warning" type="button" onClick={timbrar}/>
                            </>
                        }
                        {(permisoSuperUserGuardado || !entity.deshabilitado) &&
                            <Button label='Guardar' type="button" onClick={guardar}/>
                        }
                    </>
                }

                {
                    (facturacionMultiempresa) &&
                    <>
                        {entity.id > 0 && entity.direccion != null && (entity.articulos?.length > 0 || entity.articulosExcel?.length > 0) && !entity.timbrado &&
                            <Button label='Timbrar' className="p-button-warning" type="button"
                                    onClick={timbrarMultiempresa}/>
                        }
                    </>
                }
            </div>

            {/*Modal para cancelar el viaje*/}
            <CancelarViaje timbrado={entity.timbrado} viajeId={viajeId} visible={cancelarVisible}
                           hide={() => setCancearVisible(false)}
                           onSave={onSave} enviarCancelacion={entity?.enviarCancelacion}/>

            <Dialog visible={cambiarTarifa} header="Timbrar Multiempresa" onHide={() => setCambiarTarifa(false)}
                    footer={footerRenderModal} style={{width: '35vw'}}>
                <div className="form-row">
                    <FieldInputNumber label={'Tarifa (MXN)'} name={'facturacion'} colMd={12} error={errors?.facturacion}
                                      value={entity?.facturacion} onChange={actualizar} fractionDigits={2}/>
                </div>
            </Dialog>

            {/*Modal con la vista previa de la carta porte*/}
            {entity.id > 0 &&
                <PdfViewer header="Carta Porte" urlApi={`operaciones/viajes/programacion/${viajeId}/pdfCartaPorte`}
                           descargar={descargarCartaPorte} visible={pdfCartaPorteVisible}
                           hide={() => setPdfCartaPorteVisible(false)}/>
            }

            <CancelarCartaPorte viajeId={viajeId} visible={cancelarCP}
                                onHide={() => setCancelarCP(false)} onSave={onSave}/>

            <Dialog visible={verIncidencia} header={"Agregar Incidencia Timbrado"}
                    onHide={() => setVerIncidencia(false)}
                    footer={footerRenderModalIncidencia} style={{width: '35vw'}}>
                <FieldTextArea name="comentario" label="Incidencia Timbrado" value={incidencia?.comentario} colMd={12}
                               rows={3}
                               error={incidencia?.comentario}
                               onChange={(value, name) => setIncidencia({...incidencia, comentario: value})}/>
            </Dialog>
        </>
    );
};


export default DetalleViaje;
