/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useState} from "react";
import formReducer from "../../shared/forms/formReducer";
import {TabPanel, TabView} from "primereact/tabview";
import FormHandler from "../../shared/forms/formHandler";
import {Button} from "primereact/button";
import useApiFlotillas from "../../api/comercial/useApiFlotillas";
import constantes from "../../catalogos/constantes";
import DatosGenerales from "./secciones/datosGenerales";
import Especificaciones from "./secciones/especificaciones";
import Documentacion from "./secciones/documentacion";
import Expediente from "./secciones/expediente";
import BajaUnidad from "./secciones/bajaUnidad";
import produce from "immer";
import unidadValidacionEsquema from "./unidadValidacionEsquema";
import alerts from "../../shared/alerts";
import moment from "moment";
import FieldAutoComplete from "../../shared/controls/fieldAutoComplete";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import FieldInputNumber from "../../shared/controls/fieldInputNumber";
import ExportarRuta from "./secciones/ExportarRuta";

const DetalleUnidad = ({onUpdateStatus, onSave, unidadId, onHide, soloLectura}) => {
    const api = useApiFlotillas();
    const [esquemaValidacion, setEsquemaValidacion] = useState(unidadValidacionEsquema);
    const [exportarUnidadId, setExportarUnidadId] = useState(null)

    const defaultUnidad = () => ({
        entity: {
            accesorios: [],
        },
    });
    const [{entity, errors, ...state}, dispatch] = useReducer(formReducer, {}, defaultUnidad);

    useEffect(() => {
        let timer1 = setTimeout(loadUnidad, 100);
        // this will clear Timeout when component unmount like in willComponentUnmount
        return () => {
            clearTimeout(timer1);
        };
    }, [unidadId]);

    useEffect(() => {
        if (state.submitted === true) {
            formHandler.setValidacionSchema(esquemaValidacion);
            formHandler.validate(entity);
        }
    }, [entity]);

    const loadUnidad = async () => {
        if (unidadId == null) return;

        let unidad = await api.obtener(unidadId);

        dispatch({
            type: "reset",
            getDefault: () => {
                return {
                    entity: unidad,
                };
            },
        });
    };

    const formHandler = new FormHandler({
        validationSchema: unidadValidacionEsquema.alta,
        api: api,
        dispatch,
    });

    const save = async (callValidation) => {
        const esquema =
            callValidation === true || entity.estatus === constantes.estatus.unidades.alta
                ? unidadValidacionEsquema.alta
                : unidadValidacionEsquema.guardar;

        setEsquemaValidacion(esquema);
        formHandler.setValidacionSchema(esquema);

        const unidadGuardada = await formHandler.save(entity, true);

        if (formHandler.valdation.result === true) {
            await subirArchivos(unidadGuardada.id, entity);
            await subirArhivosAccesorios(unidadGuardada.id, entity);
            onSave();
        }

        return unidadGuardada;
    };

    const cambiarEstadoSuspendida = async (entity) => {
        if ((await alerts.preguntarSiNo(`¿Desea ${entity.suspendida ? "Activar" : "Suspender"} la unidad?`)) === true) {
            await api.cambiarEstadoSuspendida(entity.id);
            onSave();
            onHide();
        }
    };

    const darDeAlta = async () => {
        const saved = await save(true);
        if (formHandler.valdation.result === true) {
            await api.alta(saved.id);
            if (onUpdateStatus != null) onUpdateStatus();
        }
    };

    async function subirArchivos(id, unidad) {
        let documentos = unidad.documentacion.concat(unidad.expediente);
        documentos = documentos.filter((e) => e.archivo?.file != null);

        if (documentos.length > 0) {
            const formData = new FormData();
            for (const documento of documentos) {
                formData.append("files", documento.archivo.file);
            }
            formData.append("tiposDocumentos", JSON.stringify(documentos.map((e) => e.tipoDocumento.id)));

            await api.subirArchivos(id, formData);
        }
    }

    async function subirArhivosAccesorios(id, unidad) {
        const formData = new FormData();
        let indexes = [];
        for (let i = 0; i < unidad.accesorios.length; i++) {
            const archivo = unidad.accesorios[i].archivo;
            if (archivo?.file != null) {
                indexes.push(i);
                formData.append("files", archivo.file);
            }
        }

        if (indexes.length > 0) {
            formData.append("listIndexes", JSON.stringify(indexes));
            await api.subirArhivosAccesorios(id, formData);
        }
    }

    const update = (value, name) => {
        dispatch({type: "update", value, name});
    };

    const updateEquipo = (datosGenerales) => {
        let updated = {
            ...entity,
            datosGenerales,
            expediente: getExpedientes(datosGenerales, "Expediente"),
        };
        dispatch({type: "update", value: updated});
    };

    const updateNacionalCruce = (datosGenerales) => {
        const updated = produce(entity ?? {}, (draft) => {
            draft.datosGenerales = datosGenerales;
            draft.documentacion = getDocumentos(datosGenerales, datosGenerales.nacionalCruce);
            draft.especificaciones.tarjetasCruce = draft.especificaciones.tarjetasCruce.map((e) => {
                return {
                    ...e,
                    requerido:
                        datosGenerales.nacionalCruce.id === constantes.nacionalCruce.cruce &&
                        (e.tarjetaCruce.id === constantes.tarjetaCruce.tagAnzalduas ||
                            e.tarjetaCruce.id === constantes.tarjetaCruce.tagPharr)
                            ? true
                            : datosGenerales.nacionalCruce.id !== constantes.nacionalCruce.cruce
                                ? e.tarjetaCruce.requerido
                                : false,
                };
            });
        });

        dispatch({type: "update", value: updated});
    };

    function getDocumentos(datosGenerales, nacionalCruce) {
        let docs = datosGenerales.tipoUnidad?.documentos
            .filter((e) => e.nacionalCruceId === nacionalCruce.id)
            .map((e) => {
                return {
                    tipoDocumento: e,
                };
            });

        docs.forEach(function (d) {
            var doc = entity.documentos.filter((e) => e.tipoDocumento.id === d.tipoDocumento.id);
            if (doc.length > 0) {
                d.id = doc[0].id;
                d.archivo = doc[0].archivo;
                d.vigencia = doc[0].vigencia;
            }
        });

        return docs;
    }

    function getExpedientes(datosGenerales, seccion) {
        return datosGenerales.tipoEquipo.documentos
            .filter((e) => e.seccion === seccion)
            .map((e) => {
                return {
                    tipoDocumento: e,
                };
            });
    }

    const reactivar = async () => {
        const saved = await save(true);
        if (formHandler.valdation.result === true) {
            await api.reactivar(saved.id);
            if (onUpdateStatus != null) onUpdateStatus();
        }
    };

    return (
        <>
            <div className="form-container full-height">
                <TabView activeIndex={0}>
                    <TabPanel header="GENERALES" rightIcon={errors?.datosGenerales ? "pi pi-exclamation-circle" : ""}>
                        <DatosGenerales
                            datosGenerales={entity?.datosGenerales ?? {}}
                            codigoUnidad={entity?.codigoUnidad}
                            onChange={update}
                            name="datosGenerales"
                            onUpdateEquipo={updateEquipo}
                            onUpdateNacionalCruce={updateNacionalCruce}
                            errors={errors?.datosGenerales}
                        />
                    </TabPanel>
                    <TabPanel
                        header="ESPECIFICACIONES"
                        rightIcon={errors?.especificaciones || errors?.familiares ? "pi pi-exclamation-circle" : ""}
                        headerStyle={{display: entity.datosGenerales?.esMotriz !== true ? "none" : ""}}
                    >
                        <Especificaciones
                            especificaciones={entity?.especificaciones ?? {}}
                            errors={errors?.especificaciones ?? {}}
                            onChange={update}
                            name="especificaciones"
                        />
                    </TabPanel>
                    <TabPanel
                        header="DOCUMENTACIÓN"
                        rightIcon={errors?.documentacion ? "pi pi-exclamation-circle" : ""}
                        headerStyle={{display: entity.datosGenerales?.tipoEquipo == null ? "none" : ""}}
                    >
                        <Documentacion
                            name="documentacion"
                            documentacion={entity.documentacion}
                            onChange={update}
                            errors={errors ?? {}}
                        />
                    </TabPanel>
                    <TabPanel
                        header="EXPEDIENTE"
                        rightIcon={errors?.expediente || errors?.accesorios ? "pi pi-exclamation-circle" : ""}
                        headerStyle={{display: entity.datosGenerales?.tipoEquipo == null ? "none" : ""}}
                    >
                        <Expediente
                            errors={errors ?? {}}
                            onChange={update}
                            expediente={entity.expediente}
                            accesorios={entity.accesorios}
                        />
                    </TabPanel>
                    <TabPanel header={"TRAFICO"}
                              rightIcon={errors?.operador || errors?.coordinador ? "pi pi-exclamation-circle" : ""}
                              headerStyle={{display: entity.datosGenerales?.esMotriz !== true ? "none" : ""}}>
                        <FieldAutoComplete name="operador" label="Operador Asignado" colMd={6}
                                           source="empleados" filter errors={errors}
                                           className="dropdown-bottom"
                                           params={{puestoIds: [constantes.puestos.operador], todasLasEmpresas: true}}
                                           value={entity?.operador} onChange={update}/>
                        <FieldAutoComplete name="coordinador" label="Coordinador Asignado" colMd={6}
                                           source="empleados" filter errors={errors}
                                           className="dropdown-bottom"
                                           params={{
                                               puestoIds: [constantes.puestos.coordinadorTrafico, constantes.puestos.auxiliarTrafico],
                                               todasLasEmpresas: true
                                           }}
                                           value={entity?.coordinador} onChange={update}/>
                        <FieldDropDown
                            name="unidadNegocioId"
                            label="Unidad de Negocio"
                            colMd={6}
                            source="unidadesNegocio"
                            required
                            errors={errors}
                            showClear={false}
                            value={entity?.unidadNegocioId}
                            valueById={true}
                            onChange={update}
                        />
                        <FieldDropDown
                            name="unidadTipoRutaId"
                            label="Tipo Ruta"
                            colMd={6}
                            source="unidadesTipoRuta"                            
                            errors={errors}
                            showClear={false}
                            value={entity?.unidadTipoRutaId}
                            valueById={true}
                            onChange={update}
                        />
                        <FieldInputNumber
                            name="metaDiaria"
                            label="Meta Diaria"
                            colMd={6}
                            errors={errors}
                            value={entity?.metaDiaria}
                            onChange={update}
                        />
                    </TabPanel>
                    <TabPanel header="HISTORIAL">
                        <div className="form-row">
                            <table className="table">
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col" style={{width: "15%"}}>
                                        TIPO
                                    </th>
                                    <th scope="col" style={{width: "15%"}}>
                                        FECHA
                                    </th>
                                    <th scope="col" style={{width: "15%"}}>
                                        MOTIVO
                                    </th>
                                    <th scope="col" style={{width: "20%"}}>
                                        USUARIO
                                    </th>
                                    <th scope="col" style={{width: "35%"}}>
                                        COMENTARIOS
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {entity.historial?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.tipo}</td>
                                            <td>{moment(item.fecha).format("DD/MM/YYYY")}</td>
                                            <td>{item.motivo}</td>
                                            <td>{item.usuario}</td>
                                            <td>{item.comentarios}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
            {/*{JSON.stringify(entity?.documentacion)}*/}
            <div className="form-footer">
                {!soloLectura && (
                    <>
                        <Button
                            label="Exportar Ruta"
                            className="p-button-outlined"
                            type="button"
                            onClick={() => setExportarUnidadId(entity.id)}
                        />
                        {entity.estatus !== constantes.estatus.unidades.baja && (
                            <Button
                                label={entity.suspendida ? "Activar" : "Suspender"}
                                className="p-button-outlined"
                                type="button"
                                onClick={() => cambiarEstadoSuspendida(entity)}
                            />
                        )}
                        {entity.estatus === constantes.estatus.unidades.alta && (
                            <BajaUnidad onBaja={onUpdateStatus} unidad={entity}/>
                        )}
                        {entity.estatus !== constantes.estatus.unidades.baja && (
                            <Button
                                label="Guardar"
                                className="p-button-outlined"
                                type="button"
                                onClick={() => save(false)}
                                disabled={entity.datosGenerales?.tipoEquipo == null}
                            />
                        )}
                        {entity.estatus === constantes.estatus.unidades.enProceso && (
                            <Button
                                label={"Dar de Alta"}
                                type="button"
                                onClick={darDeAlta}
                                disabled={entity.datosGenerales?.tipoEquipo == null}
                            />
                        )}
                        {entity.estatus === constantes.estatus.unidades.baja && (
                            <Button
                                label={"Reactivar"}
                                className="p-button-outlined btn-outline-info"
                                type="button"
                                onClick={reactivar}
                                disabled={entity.datosGenerales?.tipoEquipo == null}
                            />
                        )}
                    </>
                )}
            </div>
            <ExportarRuta exportarUnidadId={exportarUnidadId} onCancel={()=>setExportarUnidadId(null)} />
        </>
    );
};

export default DetalleUnidad;
