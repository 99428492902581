/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useReducer, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import produce from "immer";
import ButtonIcon from "../../../shared/controls/ButtonIcon";
import alerts from "../../../shared/alerts";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldTextArea from "../../../shared/controls/fieldTextArea";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import constantes from "../../constantes";
import { default as constantesCatalogos } from "../../../catalogos/constantes";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import { Button } from "primereact/button";
import UseApiOrdenServicio from "../../../api/taller/useApiOrdenServicio";
import { RadioButton } from "primereact/radiobutton";
import OrdenTrabajoValidacionEsquema from "./ordenTrabajoValidacionEsquema";
import formReducer from "../../../shared/forms/formReducer";
import FormHandler from "../../../shared/forms/formHandler";
import CheckList from "./checklist";
import { Dialog } from "primereact/dialog";
import YupValidator from "../../../shared/YupValidator";
import printJS from "print-js";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import CancelacionOrdenTrabajo from './cancelacionOrdenTrabajo'
import ComponentToPrint from "../../pdfComponentes/checklistpdf";
import { useLoadingDispatch } from "../../../layout/show-loading-context";
import useSeguridad from "../../../Seguridad/useSeguridad";
import {useEmpresa} from "../../../layout/EmpresaProvider";
import {useAuth} from "../../../Seguridad/AuthProvider";
import LogOrdenTrabajo from "./logOrdenTrabajo";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";

const DetalleOrdenTrabajo = (servicios) => {
  const seguridad = useSeguridad();
  const soloLectura = !seguridad.esEditable(constantesCatalogos.menus.mantenimiento);
  const dispatchRoot = useLoadingDispatch();
  const [errorsReprogramacion, setErrorsReprogramacion] = useState({});
  const [copyOrdenTrabajo, setCopyOrdenTrabajo] = useState({});
  const [checkListVisible, setCheckListVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [unidadInformacion, setUnidad] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const apiOrdenServicio = UseApiOrdenServicio();
  const history = useNavigate();
  const [ordenTrabajoId, setOrdenTrabajoId] = useState(0);
  const [errorServicios, setErrorServicios] = useState(false);
  const [, setNombreSolicitante] = useState('');
  const {user} = useAuth();
  const [visibleModalCancelacion, setVisibleModalCancelacion] = useState(false);
  const [fallas, setFallas] = useState(5);

  let { id } = useParams();
  id = parseInt(id);

  const [guardarCheckList, setguardarCheckList] = useState(false);
  const [mostrarAreaCheckList, setMostrarAreaCheckList] = useState(false);
  const {disable:disableEmpresa} = useEmpresa();


  const validador = new YupValidator(OrdenTrabajoValidacionEsquema.guardar);
  const validadorReprogramar = new YupValidator(
    OrdenTrabajoValidacionEsquema.reprogramar
  );

  const validadorFinalizar = new YupValidator(
    OrdenTrabajoValidacionEsquema.finalizar
  );

  const defaultSolicitudServicio = () => ({
    entity: {
      paradas: [],
      servicios: [],
      refacciones: [],
      id: 0,
      nombreSolicitante: "Servicio Preventivo",
      mecanicos:[],
      tallerExterno:[],
      esExterno:false
    },
  });

  const [{ entity, errors, ...state }, dispatch] = useReducer(
    formReducer,
    {},
    defaultSolicitudServicio
  );

  useEffect(() => {
    if (state.submitted === true) {
      formHandler.setValidacionSchema(OrdenTrabajoValidacionEsquema.guardar);
      formHandler.validate(entity);
    }
    mostrarCheckList();
  }, [entity]);

  useEffect(() => {
    async function cargarOrden() {
      if (id === 0) {
        dispatch({ type: "reset", getDefault: () => entity });
        let solicitante = entity.nombreSolicitante.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        setNombreSolicitante(solicitante);
      }
      else {
        let ordenTrabajo = { entity: await apiOrdenServicio.obtener(id) };


        dispatchRoot({ type: 'setDatosPath', datosPath: ordenTrabajo.entity.codigo });

        dispatch({ type: "reset", getDefault: () => ordenTrabajo ?? entity });

        if (ordenTrabajo !== null) {
          setCopyOrdenTrabajo(ordenTrabajo);
          dispatch({ type: "update", value: id, name: "id" });
          setOrdenTrabajoId(id);
          setUnidad(ordenTrabajo.entity.datosUnidad);
          let solicitante = ordenTrabajo.entity.nombreSolicitante.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
          setNombreSolicitante(solicitante);
          setMostrarInfo(true);

          if (ordenTrabajo.entity.fallas.length > 1)
            setFallas(ordenTrabajo.entity.fallas.length * 2);
        }
      }
    }

    cargarOrden();
    disableEmpresa();
  }, []);

  async function actualizar(value, propertyName) {
    dispatch({ type: "update", value: value, name: propertyName });

    if (propertyName === "tipoServicio") setMostrarInfo(true);

    if (propertyName === "unidad") await obtenerInformacionUnidad(value);

    if (propertyName === "mantenimiento")
      await obtenerInformacionActividades(value);
  }

  async function back() {
    history("/taller/mantenimiento");
  }

  const formHandler = new FormHandler({
    validationSchema: OrdenTrabajoValidacionEsquema.guardar,
    api: apiOrdenServicio,
    dispatch,
  });

  const guardar = async (callValidation, esEntradaTaller) => {
    const entityToSave = {
      ...entity,
      fechaEntrada: entity.fechaEntrada != null  ? moment(entity.fechaEntrada).format('YYYY-MM-DD HH:mm:00'):null,
      fechaSalida: entity.fechaSalida != null  ? moment(entity.fechaSalida).format('YYYY-MM-DD HH:mm:00'):null,
      fechaEnTaller: entity.fechaEnTaller != null ?moment(entity.fechaEnTaller).format('YYYY-MM-DD HH:mm:00'): null,
      fechaCompromiso:entity.fechaCompromiso != null ?  moment(entity.fechaCompromiso).format('YYYY-MM-DD HH:mm:00'):null,
      esEntradaTaller: esEntradaTaller ?? false
    };

    if (entityToSave.servicios.length === 0)
      setErrorServicios(true);

    if ((await validador.validate(entityToSave)) === false) {
      await formHandler.save(entityToSave, true);
      return;
    }

    if (entityToSave.servicios.length === 0) {
      return;
    }

    let esReprogramacion = false;
    if (
      constantes.estatusOrdenesTrabajo.reprogramada === entityToSave.estatus ||
      constantes.estatusOrdenesTrabajo.programada === entityToSave.estatus
    )
      if (
        copyOrdenTrabajo.fechaEntrada !== entityToSave.fechaEntrada ||
        copyOrdenTrabajo.fechaSalida !== entityToSave.fechaSalida ||
        copyOrdenTrabajo.fechaEntaller !== entityToSave.fechaEnTaller ||
        copyOrdenTrabajo.fechaCompromiso !== entityToSave.fechaCompromiso ||
        copyOrdenTrabajo.mecanico?.id !== entityToSave.mecanico?.id ||
        copyOrdenTrabajo.tallerExterno?.id !== entityToSave.tallerExterno?.id
      ) {
        esReprogramacion = await apiOrdenServicio.esReprogramacion(entityToSave.id, {
          fechaEntrada: entityToSave.fechaEntrada,
          fechaSalida: entityToSave.fechaSalida,
        });
      }

    if (esReprogramacion) setVisibleModal(true);
    else {
      const ordenTrabajoGuardada = await formHandler.save(entityToSave, true);

      if (id !== 0) {
        let ordenTrabajo = { entity: await apiOrdenServicio.obtener(id) };

        dispatch({ type: "reset", getDefault: () => ordenTrabajo });
      }

      if (formHandler.valdation.result === true || callValidation === false) {
        dispatch({
          type: "update",
          value: ordenTrabajoGuardada.id,
          name: "id",
        });
        history("/taller/mantenimiento/editar_orden/" + ordenTrabajoGuardada.id);
      }
    }

    return entityToSave;
  };
  const terminar = async (callValidation, esEntradaTaller) => {
    const entityToSave = {
      ...entity,
      fechaEntrada: moment(entity.fechaEntrada).format('YYYY-MM-DD HH:mm:00'),
      fechaSalida: moment(entity.fechaSalida).format('YYYY-MM-DD HH:mm:00'),
      fechaEnTaller: moment(entity.fechaEnTaller).format('YYYY-MM-DD HH:mm:00'),
      fechaCompromiso: moment(entity.fechaCompromiso).format('YYYY-MM-DD HH:mm:00'),
      esEntradaTaller: esEntradaTaller ?? false
    };  

     await formHandler.save(entityToSave, true);
      if (id !== 0) {
        await apiOrdenServicio.terminarOrden(id);       
      }  
      await back();
    return entityToSave;
  };
  async function obtenerInformacionUnidad(unidad) {
    if (unidad?.id === undefined) return;

    const unidadInformacion = await apiOrdenServicio.obtenerUnidad(unidad?.id);
    setUnidad(unidadInformacion);
  }

  async function obtenerInformacionActividades(mantenimiento) {
    if (mantenimiento?.id === undefined) return;

    const info = await apiOrdenServicio.obtenerServicios(mantenimiento?.id);

    dispatch({ type: "update", value: info.servicios, name: "servicios" });
    dispatch({ type: "update", value: info.refacciones, name: "refacciones" });
  }

  async function obtenerInformacionRefacciones(servicio) {
    if (servicio?.id === undefined) return;

    const info = await apiOrdenServicio.obtenerRefacciones(servicio?.id);

    info.forEach((element) => {
      const nuevo = produce(entity.refacciones, (draft) => {
        draft.splice(0, 0, element);
      });

      dispatch({ type: "update", value: nuevo, name: "refacciones" });
    });
  }

  function actualizarElementosenLista(lista, index, value, namelist) {
    if (namelist === "servicios") {
      const nuevo = produce(lista, (draft) => {
        draft[index]["actividadServicio"] = {};
        draft[index]["descripcion"] = "";
        draft[index]["existente"] = value;
      });

      dispatch({ type: "update", value: nuevo, name: namelist });
    }

    if (namelist === "refacciones") {
      const nuevo = produce(lista, (draft) => {
        draft[index]["refaccion"] = {};
        draft[index]["nombre"] = "";
        draft[index]["unidadDeMedida"] = {};
        draft[index]["cantidad"] = "";
        draft[index]["existente"] = value;
      });

      dispatch({ type: "update", value: nuevo, name: namelist });
    }
  }

  function actualizarLista(lista, index, value, propertyName, namelist) {
    const nuevo = produce(lista, (draft) => {
      draft[index][propertyName] = value;
    });

    dispatch({ type: "update", value: nuevo, name: namelist });

    if (propertyName === "actividadServicio") {
      obtenerInformacionRefacciones(value);
    }

    if (propertyName === "existente") {
      actualizarElementosenLista(lista, index, value, namelist);
    }
  }

  function agregarALista(lista, namelist) {
    const nuevo = produce(lista, (draft) => {
      draft.splice(0, 0, { existente: true });
    });

    if (namelist === "servicios")
      setErrorServicios(false);


    dispatch({ type: "update", value: nuevo, name: namelist });
  }

  function eliminarDeLista(lista, index, namelist) {
    const nuevo = produce(lista, (draft) => {
      draft.splice(index, 1);
    });

    dispatch({ type: "update", value: nuevo, name: namelist });
  }

  const guardarReprogramacion = async (callValidation) => {
    setSubmitted(true);

    if ((await validadorReprogramar.validate(entity)) === false) {
      setErrorsReprogramacion(validadorReprogramar.errors);
      return;
    }

    const ordenTrabajoGuardada = await formHandler.save(entity, true);

    if (formHandler.valdation.result === true || callValidation === false) {
      dispatch({
        type: "update",
        value: ordenTrabajoGuardada.id,
        name: "id",
      });
      history("/taller/mantenimiento/editar_orden/" + ordenTrabajoGuardada.id);
    }

    setSubmitted(false);
    setVisibleModal(false);
  };

  async function cancelarOrdenTrabajo() {
    setVisibleModalCancelacion(false);
  }

  async function openModalCancelar() {
    setVisibleModalCancelacion(true);
  }

  async function obtenerCheckList() {

    if (!entity.esMotriz)
      await actualizar(0, "odometro");

    const newEntity = produce(entity, draft => {
      draft["odometro"] = entity.esMotriz ? draft["odometro"] : 0
    });

    if ((await validadorFinalizar.validate(newEntity)) === false) {
      let errors = validadorFinalizar.errors;
      dispatch({ type: 'validate', errors });
      return;
    }

    setCheckListVisible(true);
    setMostrarInfo(false);
  }

  async function guardarCheck() {
    setguardarCheckList(true);
  }

  async function cancelarGuardado() {
    setguardarCheckList(false);
  }

  const componentRef = useRef();

  const PrintPdf = useReactToPrint({
    content: () => componentRef.current,
  });
  const update = (value, name) => dispatch({type: "update", value, name});

  const agregarMecanico = () => {
    const newValue = produce(entity.mecanicos, (draft) => {
        draft.push({id: 0});
    });

    update(newValue, "mecanicos");
  };
  const actualizarMecanicos = (value, campo, index) => {
    const newValue = produce(entity.mecanicos, (draft) => {
      draft[index] = value;
    });
  
    update(newValue, "mecanicos");
  };
  const eliminarMecanico = (index) => {
    const newValue = produce(entity.mecanicos, (draft) => {
        draft.splice(index, 1);
    });

    update(newValue, "mecanicos");
};
const agregarExterno = () => {
  const newValue = produce(entity.tallerExterno, (draft) => {
      draft.push({id: 0});
  });

  update(newValue, "tallerExterno");
};
const actualizarExterno = (value, campo, index) => {
  const newValue = produce(entity.tallerExterno, (draft) => {
    draft[index] = value;
  });

  update(newValue, "tallerExterno");
};
const eliminarExterno = (index) => {
  const newValue = produce(entity.tallerExterno, (draft) => {
      draft.splice(index, 1);
  });

  update(newValue, "tallerExterno");
};
  const mostrarCheckList = async () => {
    setMostrarAreaCheckList(false);
    if
      ((
        entity.tipoServicio?.id === constantes.tiposServicio.MantenimientoCorrectivo &&
        entity.unidad?.tipoUnidadId !== constantes.tiposUnidad.Utilitario &&
        entity.unidad?.tipoUnidadId !== constantes.tiposUnidad.Pipa
      ) ||
      (
        entity.tipoServicio?.id === constantes.tiposServicio.MantenimientoPreventivo &&
        entity.unidad?.tipoUnidadId !== constantes.tiposUnidad.Utilitario
      )) {
      setMostrarAreaCheckList(true);
    }
  };

  const confirmarOrden = async () => {
    if ((await alerts.preguntarSiNoAdvertencia("¿Desea confirmar la orden de trabajo?")) === true) {
      await apiOrdenServicio.confirmar(entity.id, []);
      history("/taller/mantenimiento");
    }
  };

  const entradaTaller = async () => {
    await guardar(true, true);
  };

  return (
    <>
    
      <CancelacionOrdenTrabajo ordenTrabajoId={id} setVisible={cancelarOrdenTrabajo} esVisibleModal={visibleModalCancelacion} />

      <Dialog
        header="¿Desea reprogramar el mantenimiento?"
        visible={visibleModal}
        style={{ width: "30vw" }}
        onHide={() => setVisibleModal(false)}
      >
        <div className="form-row">
          <FieldDropDown
            name="motivoReprogramacion"
            label="Motivo de Reprogramación"
            colMd={12}
            source="general/Motivo reprogramacion"
            errors={errorsReprogramacion}
            scrollHeight={150}
            filter
            value={entity.motivoReprogramacion}
            onChange={actualizar}
          />
          <FieldTextArea
            name="ComentariosReprogramacion"
            label="Comentarios"
            colMd={12}
            rows={6}
            errors={errorsReprogramacion}
            value={entity.ComentariosReprogramacion}
            onChange={actualizar}
          />
        </div>
        <div className="">
          <Button
            label="Confirmar"
            className={"float-right"}
            type="button"
            onClick={guardarReprogramacion}
          />
        </div>
      </Dialog>

      <div className="form-container">
        <div className="form-filtro form-row">
          <FieldDropDown
            name="tipoServicio"
            label="Tipo de Servicio"
            colMd={2}
            source="general/tipo de servicio"
            disabled={ordenTrabajoId > 0}
            errors={errors}
            value={entity.tipoServicio}
            requiredIoDocumentText
            onChange={actualizar}
          />

          {entity.tipoServicio?.id ===
            constantes.tiposServicio.MantenimientoPreventivo && (
              <>
                <FieldDropDown
                  name="mantenimiento"
                  label="Mantenimiento"
                  colMd={2}
                  required
                  source="tiposMantenimiento"
                  disabled={ordenTrabajoId > 0}
                  errors={errors}
                  value={entity.mantenimiento}
                  onChange={actualizar}
                />
              </>
            )}

          <FieldAutoComplete
            required
            name="unidad"
            label="No Económico"
            colMd={2}
            errors={errors}
            source="unidades"
            params={{ todasLasEmpresas: true }} 
            freeText={true}
            disabled={ordenTrabajoId > 0}
            value={entity.unidad}
            onChange={actualizar}
          />

          <FieldTextBox
            name="tipoUnidad"
            disabled={true}
            errors={errors}
            value={entity.unidad?.tipoUnidad}
            label="Tipo Unidad"
            colMd={2}
          />

          <FieldTextBox
            name="nombreSolicitante"
            disabled={true}
            errors={errors}
            value={entity.nombreSolicitante}
            label="Nombre Solicitante"
            colMd={2}
            onChange={actualizar}
          />
          <FieldCheckBox
                name="esExterno"
                label="Externo"
                value={entity.esExterno}
                onChange={actualizar}
                className="py-1"
                colMd={3}
            />
          <div className="field-iconos btn-group">
            <ButtonIcon title="Regresar" onClick={back} iconName={"BiArrowBack"} />
            {!soloLectura &&
              <>
                {ordenTrabajoId > 0 && entity.estatus !== constantes.estatusOrdenesTrabajo.MttoRealizado && entity.estatus !== constantes.estatusOrdenesTrabajo.cancelado && (
                  <ButtonIcon title="Cancelar" onClick={openModalCancelar} iconName={"FcCancel"} />
                )}
                {entity.estatus !== constantes.estatusOrdenesTrabajo.MttoRealizado && entity.estatus !== constantes.estatusOrdenesTrabajo.cancelado &&
                  <>
                    <ButtonIcon title="Guardar" iconName={"AiOutlineSave"} onClick={guardar} />
                  </>
                }
                {entity.estatus !== constantes.estatusOrdenesTrabajo.MttoRealizado && entity.estatus !== constantes.estatusOrdenesTrabajo.cancelado && entity.estatus !== constantes.estatusOrdenesTrabajo.pteProgramar &&
                  <>
                    <ButtonIcon title="Terminar Orden" iconName={"FaCheckCircle"} onClick={terminar} />
                  </>
                }

                {ordenTrabajoId > 0 && !checkListVisible && entity.estatus !== constantes.estatusOrdenesTrabajo.cancelado &&
                  <ButtonIcon title="Impresion" className={'secondary-icon'} iconName={'BiPrinter'}
                    onClick={() => printJS(`${process.env.REACT_APP_API_URL}/taller/mantenimiento/OrdenesTrabajo/${ordenTrabajoId}/pdf/${user.nombre}`)}
                  />
                }
              </>
            }

            {ordenTrabajoId > 0 && checkListVisible &&
              <div>
                {/* <CheckList ref={componentRef} /> */}
                <ButtonIcon title="Impresion" className={'secondary-icon'} iconName={'BiPrinter'} onClick={() => PrintPdf()} />
              </div>
            }

            {!soloLectura &&
              <>
                {(entity.estatus === constantes.estatusOrdenesTrabajo.programada || entity.estatus === constantes.estatusOrdenesTrabajo.reprogramada) &&
                  <ButtonIcon title="Entrada a Taller" className={"action"} iconName={"FaWrench"} onClick={entradaTaller} />
                }

                {entity.estatus === constantes.estatusOrdenesTrabajo.entradaTaller && !checkListVisible && mostrarAreaCheckList &&
                  <ButtonIcon title="Checklist" onClick={obtenerCheckList} className={"action"} iconName={"BiCheckCircle"} />
                }

                {(entity.estatus === constantes.estatusOrdenesTrabajo.programada || entity.estatus === constantes.estatusOrdenesTrabajo.reprogramada
                ) && !checkListVisible
                  &&
                  (
                    <>
                      {/* {mostrarAreaCheckList &&} */}

                      {(
                        (entity.tipoServicio?.id === constantes.tiposServicio.MantenimientoCorrectivo &&
                          (
                            entity.unidad?.tipoUnidadId === constantes.tiposUnidad.Utilitario ||
                            entity.unidad?.tipoUnidadId === constantes.tiposUnidad.Pipa
                          )
                        ) ||
                        (entity.unidad?.tipoUnidadId === constantesCatalogos.tiposUnidad.Utilitario &&
                          entity.tipoServicio?.id === constantes.tiposServicio.MantenimientoPreventivo)
                      ) &&
                        <ButtonIcon title="Confirmar Orden" onClick={confirmarOrden} className={"action"} iconName={"IoDocumentText"} />
                      }

                    </>
                  )}

                {entity.estatus === constantes.estatusOrdenesTrabajo.entradaTaller && checkListVisible &&
                  <ButtonIcon title="" onClick={guardarCheck} className={"action"} iconName={"IoDocumentText"} />
                }
              </>
            }
          </div>
        </div>
        <br />
        {!checkListVisible &&
          <div
            className={`section-container ${submitted === true ? "submitted" : ""}`}
          >
            <div className="flex-col-6">
              <div className="form-row">
                <div className={`form-group col-md-11 col-sm-11`}>
                  <h5>Programacion</h5>
                </div>               
              </div>

              <div className="form-row">
              <div className={`form-group col-md-8 col-sm-8 margintop`}>
                  <div className="form-row">
                    <div className={`form-group col-md-4 col-sm-4`}>
                      <label>* Fecha y Hora de Entrada</label>
                    </div>

                    <div className={`form-group col-md-8 col-sm-8`}>
                      <FieldCalendar
                        name="fechaEntrada"
                        label=""
                        required={true}
                        className="small"                        
                        showButtonBar={false}
                        value={entity.fechaEntrada}
                        colMd={6}
                        errors={errors}
                        showTime={true}
                        onChange={actualizar}
                      />
                    </div>
                  </div>
                </div>
                               
              </div>
              <div className="form-row">
                <div className={`form-group col-md-8 col-sm-8`}>
                  <div className="form-row">
                    <div className={`form-group col-md-4 col-sm-4`}>
                      <label>* Fecha y Hora de Compromiso Inicial</label>
                    </div>

                    <div className={`form-group col-md-8 col-sm-8`}>
                      <FieldCalendar
                        name="fechaSalida"
                        className="small"
                        required
                        showButtonBar={false}
                        value={entity.fechaSalida}
                        colMd={6}                        
                        errors={errors}
                        showTime={true}
                        onChange={actualizar}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
              <div className={`form-group col-md-8 col-sm-8 margintop`}>
                  <div className="form-row">
                    <div className={`form-group col-md-4 col-sm-4`}>
                      <label>Fecha y Hora en Taller</label>
                    </div>

                    <div className={`form-group col-md-8 col-sm-8`}>
                      <FieldCalendar
                        name="fechaEnTaller"
                        label=""
                        className="small"                        
                        showButtonBar={false}
                        value={entity.fechaEnTaller}
                        colMd={6}
                        showTime={true}
                        onChange={actualizar}
                      />
                    </div>
                  </div>
                </div>
                               
              </div>
              <div className="form-row">
                <div className={`form-group col-md-8 col-sm-8`}>
                  <div className="form-row">
                    <div className={`form-group col-md-4 col-sm-4`}>
                      <label>Fecha y Hora Salida Inicial</label>
                    </div>

                    <div className={`form-group col-md-8 col-sm-8`}>
                    <FieldCalendar
                        name="fechaCompromiso"
                        label=""
                        className="small"                        
                        showButtonBar={false}
                        value={entity.fechaCompromiso}
                        colMd={6}
                        showTime={true}
                        onChange={actualizar}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {entity.esExterno &&(  
                <div className="form-row">
              
                <FieldInputNumber                    
                      name="costoservicio"
                      label="Costo Servicio"
                      colMd={6}               
                      fractionDigits={2}    
                      value={entity.costo}
                      onChange={actualizar}
                    />
              </div>
             )}
              {!entity.esExterno &&(   
              <div className="form-row">
                <div className="col-6">
                    <table className="table table-borderless">
                        <thead className="thead-light">
                        <tr>
                            <th style={{width: "25%"}}>Mecánico</th>        

                            <th className={"ag-grid header-icons"} style={{width: "10%"}}>
                                {!soloLectura && (
                                    <ButtonIcon
                                        title={"Agregar Mecanico"}
                                        iconName={"SiAddthis"}
                                        className="grid-action"
                                        onClick={agregarMecanico}
                                    />
                                )}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {entity?.mecanicos?.map((item, index) => {
                            return (
                                <tr style={{position: "relative"}} key={index}>
                                    <td style={{display: "flex"}} className="input">
                                    <FieldDropDown
                                          name="mecanico"
                                          label="Mecánico"
                                          colMd={12}
                                          source="mecanicos"
                                          errors={errors}
                                          value={item.id}
                                          onChange={(value, pName) => actualizarMecanicos(value, pName, index)}
                                        />
                                    </td>
                                   
                                   

                                    <td>
                                        {!soloLectura && (
                                            <ButtonIcon
                                                title={"Eliminar"}
                                                iconName={"RiDeleteBinLine"}
                                                onClick={() => eliminarMecanico(index)}
                                            />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
              </div>
             )}
            {entity.esExterno &&(            
              <div className="form-row">
                  <div className="col-6">
                      <table className="table table-borderless">
                          <thead className="thead-light">
                          <tr>
                              <th style={{width: "25%"}}>Taller Externo</th>        

                              <th className={"ag-grid header-icons"} style={{width: "10%"}}>
                                  {!soloLectura && (
                                      <ButtonIcon
                                          title={"Agregar Externo"}
                                          iconName={"SiAddthis"}
                                          className="grid-action"
                                          onClick={agregarExterno}
                                      />
                                  )}
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          {entity?.tallerExterno?.map((item, index) => {
                              return (
                                  <tr style={{position: "relative"}} key={index}>
                                      <td style={{display: "flex"}} className="input">
                                      <FieldDropDown
                                            name="tallerExterno"
                                            label="Taller Externo"
                                            colMd={12}
                                            source="externos"
                                            errors={errors}
                                            value={item.id}
                                            onChange={(value, pName) => actualizarExterno(value, pName, index)}
                                          />
                                      </td>
                                    
                                    

                                      <td>
                                          {!soloLectura && (
                                              <ButtonIcon
                                                  title={"Eliminar"}
                                                  iconName={"RiDeleteBinLine"}
                                                  onClick={() => eliminarExterno(index)}
                                              />
                                          )}
                                      </td>
                                  </tr>
                              );
                          })}
                          </tbody>
                      </table>
                  </div>
              </div>
             )}
            </div>
            <div className="flex-col-6">
              <h5>Datos Unidad</h5>
              <div className="form-row">
                <div className={`form-group col-md-12 col-sm-12`}>
                  <table className="table table-borderless tablemargin">
                    <thead>
                      <tr>
                        <th>PLACAS</th>
                        <th>MARCA</th>
                        <th>MODELO</th>
                        <th>AÑO</th>
                        <th>ODOMETRO ACTUAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{unidadInformacion?.placas}</td>
                        <td>{unidadInformacion?.marca}</td>
                        <td>{unidadInformacion?.modelo}</td>
                        <td>{unidadInformacion?.anio}</td>
                        <td>
                          <FieldInputNumber label="" className="small" name="odometro" showButtonBar={false} disabled={!entity.esMotriz}
                            value={entity.odometro} colMd={6} errors={errors} onChange={actualizar} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="form-row">
                <div className={`form-group col-md-10 col-sm-10`}>
                  <table className="table table-borderless tablemargin">
                    <thead>
                      <tr>
                        <th>ULTIMO MANTENIMIENTO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{unidadInformacion.ultimoMantenimiento}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
        <br />
        {mostrarInfo && (
          <>
            {ordenTrabajoId > 0 && entity.fallas.length > 0 && (
              <>
                <div
                  className={`section-container ${submitted === true ? "submitted" : ""
                    }`}
                >
                  <div className="flex-col-6">
                    <h5>Descripcion de Fallas</h5>
                    {entity.fallas?.map((item, index) => {
                      return (
                        <div key={index} style={{ marginTop: 8 }}>
                          <FieldTextBox name="descripcion"
                            value={index + 1 + '.- ' + item.descripcion}
                            disabled={entity.id > 0}
                          />
                        </div>
                      )
                    }
                    )}
                  </div>
                  <div className="flex-col-6">
                    <h5>Comentarios</h5>
                    <FieldTextArea
                      name="comentarios"
                      errors={errors}
                      required
                      onChange={actualizar}
                      value={entity.comentarios}
                      rows={fallas}
                    />
                  </div>
                </div>
              </>
            )}

            {(ordenTrabajoId === 0 || entity.fallas.length === 0) && (
              <>
                <div
                  className={`section-container ${submitted === true ? "submitted" : ""
                    }`}
                >
                  <div className="flex-col-12">
                    <h5>Comentarios</h5>
                    <FieldTextArea
                      onChange={actualizar}
                      required
                      errors={errors}
                      name="comentarios"
                      value={entity.comentarios}
                      rows={fallas}
                    />
                  </div>
                </div>
              </>
            )}

            <br />

            <div
              className={`section-container ${errorServicios === true ? "danger" : ""
                }`}
            >
              <div id="servicios" className="flex-col-6 danger  minheight">
                <h5>Servicios a Realizar</h5>

                <div className="">
                  <table className="table main-edit-table" align="right">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ width: "10%" }}>
                          ACTIVIDAD
                        </th>
                        <th scope="col" style={{ width: "10%" }}></th>
                        <th scope="col" style={{ width: "60%" }}></th>
                        <th style={{ width: "10%" }}>
                          <ButtonIcon
                            title={"Agregar Servicio"}
                            iconName={"SiAddthis"}
                            onClick={() =>
                              agregarALista(entity.servicios, "servicios")
                            }
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entity.servicios?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="input">
                              <div className="p-field-radiobutton main-item-container">
                                <RadioButton
                                  name="existente"
                                  value={true}
                                  onChange={(e, name) =>
                                    actualizarLista(
                                      entity.servicios,
                                      index,
                                      e.value,
                                      "existente",
                                      "servicios"
                                    )
                                  }
                                  checked={item.existente === true}
                                />
                                <label>Existente</label>
                              </div>
                            </td>
                            <td className="input">
                              <div className="p-field-radiobutton main-item-container">
                                <RadioButton
                                  name="existente"
                                  value={false}
                                  onChange={(e, name) =>
                                    actualizarLista(
                                      entity.servicios,
                                      index,
                                      e.value,
                                      "existente",
                                      "servicios"
                                    )
                                  }
                                  checked={item.existente === false}
                                />
                                <label>Nuevo</label>
                              </div>
                            </td>
                            <td className="input">
                              {item.existente === true && (
                                <>
                                  <FieldAutoComplete
                                    name="actividadServicio"
                                    errors={errors?.[`servicios[${index}]`]}
                                    source="ActividadesServiciosSearch"
                                    freeText={true}
                                    value={item.actividadServicio}
                                    onChange={(value) =>
                                      actualizarLista(
                                        entity.servicios,
                                        index,
                                        value,
                                        "actividadServicio",
                                        "servicios"
                                      )
                                    }
                                  />
                                </>
                              )}

                              {item.existente === false && (
                                <>
                                  <FieldTextBox
                                    errors={errors?.[`servicios[${index}]`]}
                                    name="descripcion"
                                    upperCase={true}
                                    onChange={(value, pName) =>
                                      actualizarLista(
                                        entity.servicios,
                                        index,
                                        value,
                                        "descripcion",
                                        "servicios"
                                      )
                                    }
                                    value={item.descripcion}
                                  />
                                </>
                              )}
                            </td>
                            <td className="input">
                              <ButtonIcon
                                title={"Eliminar"}
                                onClick={() =>
                                  eliminarDeLista(
                                    entity.servicios,
                                    index,
                                    "servicios"
                                  )
                                }
                                iconName={"RiDeleteBinLine"}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex-col-6 minheight">
                <h5>Refacciones</h5>
                <div className="form-row">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" className="center" style={{ width: "10%" }}>
                          CODIGO
                        </th>
                        <th scope="col" style={{ width: "50%" }}>
                          DESCRIPCION
                        </th>
                        <th scope="col" style={{ width: "5%" }}>
                          UNIDAD
                        </th>
                        <th scope="col" className="center" style={{ width: "15%" }}>
                          CANTIDAD
                        </th>
                        <th scope="col" className="center" style={{ width: "15%" }}>
                          PRECIO
                        </th>
                        <th className={"input"} style={{ width: "10%" }}>
                          <ButtonIcon
                            title={"Agregar Refacciones"}
                            iconName={"FcEmptyBattery"}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entity.refacciones?.map((item, index) => {
                        const isVisible = item.estatusSAAS === 'Negativo' ? true : false;
                        return isVisible ? (
                          <tr key={index}>
                            <td className="input center">
                              <label>
                                {item.existente === true && item.codigo}

                                {item.existente === true &&
                                  item.refaccion?.codigo}
                              </label>
                            </td>
                            <td className="input center">
                              {item.existente === true && (
                                <>
                                  <FieldAutoComplete
                                    name="refaccion"
                                    errors={errors?.[`refacciones[${index}]`]}
                                    source="refacciones"
                                    freeText={true}
                                    value={item.refaccion}
                                    disabled={true}
                                    onChange={(value) =>
                                      actualizarLista(
                                        entity.refacciones,
                                        index,
                                        value,
                                        "refaccion",
                                        "refacciones"
                                      )
                                    }
                                  />
                                </>
                              )}

                              {item.existente === false && (
                                <>
                                  <FieldTextBox
                                    name="nombre"
                                    upperCase={true}
                                    errors={errors?.[`refacciones[${index}]`]}
                                    disabled={true}
                                    onChange={(value) =>
                                      actualizarLista(
                                        entity.refacciones,
                                        index,
                                        value,
                                        "nombre",
                                        "refacciones"
                                      )
                                    }
                                    value={item.nombre}
                                  />
                                </>
                              )}
                            </td>
                            <td className="input center">
                              <label>
                                {item.existente === true &&
                                  item.unidadDeMedida?.nombre}
                                {item.existente === true &&
                                  item.refaccion?.unidadDeMedida?.nombre}
                              </label>

                              {item.existente === false && (
                                <FieldDropDown
                                  name="unidadDeMedida"
                                  source="unidadesDeMedida"
                                  required
                                  errors={errors?.[`refacciones[${index}]`]}
                                  value={item.unidadDeMedida}
                                  onChange={(value) =>
                                    actualizarLista(
                                      entity.refacciones,
                                      index,
                                      value,
                                      "unidadDeMedida",
                                      "refacciones"
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td className="input center">
                              <label>
                                {item.existente === true &&
                                  item.cantidad}
                              </label>
                            </td>
                            <td className="input center">
                              <label>
                                {item.existente === true &&
                                  item.refaccion?.costo}
                              </label>
                            </td>
                            <td className="input">

                            </td>
                          </tr>
                        ) : null;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={'flex-col-6'}>
                  <h5>Historial</h5>
                  <div>
                      <LogOrdenTrabajo data={entity.logs ?? []}></LogOrdenTrabajo>
                  </div>
              </div>
            </div>
          </>
        )}

        <br />
        {checkListVisible &&
          <CheckList guardarInformacion={guardarCheckList} cancelarGuardado={cancelarGuardado} ordenTrabajoId={ordenTrabajoId} tipoUnidadId={entity.unidad?.tipoUnidadId} />
        }

        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <ComponentToPrint numeroOrdenTrabajo={entity?.numeroOrdenTrabajo} numeroEconomico={entity?.numeroEconomico} ordenTrabajoId={ordenTrabajoId} />
          </div>
        </div>

      </div>

    </>
  );
};

export default DetalleOrdenTrabajo;
