/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {Dropdown} from "primereact/dropdown";
import useSourceUtils from "../../useSouceUtils";
import useInputValidation from "./useInputValidation";

const FormDropDown = ({value,onChange, field, parent, options, disabled, formDisabled, source, filter, orderBy, required, showClear, validators, params, onValidate, width, ...props}) => {


    const {
        currentOptions,
        setSelectedId,
        getSelected
    } = useSourceUtils(value, options, source, params, false, true,  orderBy);

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required,
        validators,
        onValidate,
        parent
    });

    const onDropChange =(event) => {
        const ids= [event.value?.id];
        setSelectedId(ids)
        onChange(event.value?.id, event.value);
    }

    return (
        <>
            <Dropdown
                value={getSelected()}
                id={field}
                style={{width: width ?? '100%'}}
                options={currentOptions}
                onChange={onDropChange}
                className={`${validationErrorCss}`}
                optionLabel="nombre"
                filterBy="nombre"
                showClear={showClear}
                disabled={disabled??formDisabled}
                placeholder={`Seleccionar...`}
                {...props}
                filter={true}
            />
        </>
    )
}

export default FormDropDown;
