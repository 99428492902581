import {
    colorIndicadores,
    colorMap,
    indicadorColorMap,
    nombreColorIndicador
} from "./SeguimentoConstantes";
import constantes from "../../../administracion/constantes";
import moment from "moment";

export const getIcon = (item, viaje) => {
    // item.esCaja = true
    const path = item.esCaja ? "M4 0h9s4 0 4 4v14.6667s0 4 -4 4h-9s-4 0 -4 -4v-14.6667s0 -4 4 -4" : 'M17.4225 1.49461L29.0961 26.064L17.5327 20.9555L5.80922 26.0319L17.4225 1.49461Z'
    let anchor = null;
    if (window.google != null) {
        anchor = item.esCaja ? new window.google.maps.Point(9, 11) : new window.google.maps.Point(18, 16)
    }
    let color = "gray";
    if ((viaje?.viajeId > 0 || item.esCaja)){
        color = colorMap.get(viaje?.color) ?? 'green';
    }


    return {
        path: path,
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: 'black',
        rotation: item.orientacion,
        scale: 1,
        anchor
    }
};


export const getUnidades = (viaje) => {
    let result = []
    if (viaje.unidadId != null)
        result.push(viaje.unidadId)
    if (viaje.cajaId != null)
        result.push(viaje.cajaId)

    return result;
}


export const programado = (estatus) => {
    return estatus === constantes.estatusViajes.programado;
}


export const containerStyle = {
    height: '100%'
};

export const getColorIndicadores = (indicador) => {
    if (indicador?.color == null || indicador.color === 0)
        return 'gris'

    return colorIndicadores[indicador.color];
}

export const getContrast = (indicador) => {
    if (indicador == null)
        return ''

    return indicador?.color !== nombreColorIndicador.gris && indicador?.color !== nombreColorIndicador.amarillo && indicador?.color !== 0
        ? 'Contraste' : '';
}

export const getMapSliderRanges = () => {
    let horaActual = moment()
    let fechas = []
    let range = {
        0: '0'
    };
    // let now = moment().startOf('minute')
    for (let i = 0; i < 24; i++) {
        range[i] = `${horaActual.format('HH:mm')} hrs`;
        fechas[i] = horaActual.format('yyyy-MM-DD HH:mm:00');
        horaActual = horaActual.add(-1, 'hour');

    }
    horaActual = horaActual.add(-24, 'hour');
    range[24] = `${horaActual.format('ddd hh:mm')}`;
    fechas.push(horaActual.format('yyyy-MM-DD HH:mm:00'));

    horaActual = horaActual.add(-24, 'hour');
    range[25] = `${horaActual.format('ddd hh:mm')}`
    fechas.push(horaActual.format('yyyy-MM-DD HH:mm:00'));

    return {range, fechas};
}

export const actualizarColorViaje = (viaje) => {

    viaje.color = nombreColorIndicador.verde;
    if (viaje.indicadores == null)
        return viaje

    for (let indicadorId in viaje.indicadores) {
        indicadorId = parseInt(indicadorId)
        const indicador = viaje.indicadores[indicadorId];
        if (indicador.color === nombreColorIndicador.verde || indicador.color === nombreColorIndicador.gris || indicador.color === 0) {
            continue;
        }

        const colorOFuncion = indicadorColorMap.get(indicadorId);

        const color = typeof colorOFuncion === 'function' ?
            colorOFuncion(viaje, viaje.indicadores[indicadorId]) :
            colorOFuncion;

        if (color > viaje.color) {
            viaje.color = color;
        }

        if (color === nombreColorIndicador.rojo) {
            break;
        }
    }

    return viaje;
}


export const libraries = ["drawing"];