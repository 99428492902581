/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./EstatusDiarios.css";
import UnidadDia from "./UnidadDia";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Captura from "./Captura";
import { estatusMap } from "./StatusButton";

const EstatusDiarioDia = (
    unidad,
    unidadIndex,
    contadorUnidades,
    mostrarDivision,
    setUnidadDiaActual,
    dia,
    dias,
    index,
    onUpdateDatos,
    otrasUnidades = false,
    editable
) => {
    let unidadDia = dia.unidades?.get(unidad.id) ?? {};
    unidadDia.numEconomico = unidad.numEconomico;
    unidadDia.unidadId = unidad.id;
    unidadDia.enabled = !unidad.enRenta;
    unidadDia.enRenta = !otrasUnidades && unidad.enRenta ? "En renta" : "";

    const estatus = estatusMap.get(unidadDia?.estatusId) ?? {};
    let diaAnterior = null;
    if (dia.esDiaActual) {
        diaAnterior = dias[index + 1].unidades.get(unidad.id);
    }
    return (
        <>
            <React.Fragment>
                <div
                    style={{
                        borderTop: unidadIndex === 0 && mostrarDivision ? "9px solid  #737373" : "",
                    }}
                    className="cell"
                    key={unidadIndex}
                >
                    <div style={{ gridRowEnd: "span 3", backgroundColor: estatus?.color }}></div>
                    {unidadDia?.estatusId != null && !dia.editable && (
                        <UnidadDia
                            datos={unidadDia}
                            dia={dia}
                            unidadId={unidad.id}
                            onClick={() =>
                                setUnidadDiaActual({
                                    ...unidadDia,
                                    fecha: dia.fecha,
                                    esDiaActual: false,
                                })
                            }
                        ></UnidadDia>
                    )}
                    {dia.editable && unidad.isDefault !== true && (
                        <Captura
                            unidaDia={unidadDia}
                            dia={dia}
                            otraEmpresa={unidad.otraEmpresa}
                            unidadDiaAnterior={diaAnterior}
                            onUpdateDatos={(datos) => onUpdateDatos(dia.fecha, datos)}
                            editable={editable}
                        />
                    )}
                </div>
            </React.Fragment>
        </>
    );
};

export default EstatusDiarioDia;
