/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import React, {useEffect, useRef, useState} from "react";
import {dateToString, utcToString} from "../../../../shared/format";
import {useSeguimientoMonitoreo} from "../SeguimientoMonitoreoProvider";
import constantes from "../../../../catalogos/constantes";
import useApiViajeSeguimientoMonitoreo from "../../../../api/operaciones/useApiSeguimientoMonitoreo";
import {writeToClipboard} from "../../../../shared/utility";
import {useLoadingDispatch} from "../../../../layout/show-loading-context";

const CompartirInformacionModal = ({visibleCompartir, onClose}) => {

    const [data, setData] = useState([])
    const [url, setUrl] = useState()
    const divRef = useRef(null)
    const api = useApiViajeSeguimientoMonitoreo();
    const dispatch = useLoadingDispatch();

    const {
        viajesFiltrados,
        viajeVisible
    } = useSeguimientoMonitoreo();


    useEffect(() => {
        if (visibleCompartir) {
            obtenerToken().then()
        }
    }, [visibleCompartir]);

    const obtenerToken = async () => {
        const viajes = viajesFiltrados.filter(e => viajeVisible(e.viajeId));

        const enviarViajes = viajes.map(e=> {return {viajeId:e.viajeId, tipoEquipoId:constantes.tiposEquipo.motriz, unidadId:e.unidadId, cajaId:e.cajaId}})
        const token = await api.generarToken({viajes:enviarViajes, duracion:{porTiempo: false, horas:0, minutos:0}},{showSuccess:false})
        setUrl(`${process.env.REACT_APP_API_CLIENTES_URL}/mapa/${token}`)

        setData(viajes)
    }

    async function copyHTMLToClipboard() {
        const htmlContent = divRef.current.innerHTML;

        try {
            await navigator.clipboard.write([
                new window.ClipboardItem({
                    "text/html": new Blob([htmlContent], {type: "text/html"})
                })
            ]);

            dispatch({type: 'toast', message: 'Se han copiado los viajes al portapapeles'});

            onClose()
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    }

    const copiarViajesSeleccionados = async () => {
        let texto = '';

        const viajes = data

        for (let i=0; i<viajes.length; i++ ) {
            const viaje = viajes[i];

            const eta = `${utcToString(viaje.eta?.eta, 'DD-MMM-YYYY hh:mm A')}`

            texto += `*Truck:* ${viaje.unidad??''} \n*Trailer:* ${viaje.caja??''} \n*Driver:* ${viaje.operador??''} \n*Id Viaje:* ${viaje.idViaje}\n*Carta Porte*: ${viaje.cartaPorte}\n`
            texto += `*Route:* ${viaje.origen??''} -> ${viaje.destino??''} \n*Location:* ${viaje.ubicacion??''} \n*ETA* ${eta??''} \n\n`;
        }

        texto+= `*Cuenta espejo*:\n${url}`

        writeToClipboard(texto)

        dispatch({type: 'toast', message: 'Se han copiado los viajes al portapapeles'});

        onClose()
    }


    return (
        <Dialog header="Compartir Información"
                className={'custom-footer'}
                visible={visibleCompartir} style={{width: '80vw', maxWidth: '1200px'}}
                onHide={onClose}
        >
            <div ref={divRef} style={{maxHeight:'400px', overflowY:'auto'}}>
                <table id={"table-informacion"} style={{maxWidth: '2000px', fontSize: '0.8em'}} className="table">
                    <thead className="thead-light">
                    <tr>
                        <th style={{backgroundColor:'#e9ecef'}} >Unidad</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Caja</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Operador</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Origen</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Destino</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Carta Porte</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Id Viaje</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Ubicación</th>
                        <th style={{backgroundColor:'#e9ecef'}} >Eta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data && data.map((item) =>
                        <tr>
                            <td>{item.unidad}</td>
                            <td>{item.caja}</td>
                            <td style={{maxWidth: '100px'}} className={'td-no-break'}
                                title={item.operador}>{item.operador}</td>
                            <td style={{maxWidth: '130px'}} className={'td-no-break'}
                                title={item.origen}>{item.origen}</td>
                            <td style={{maxWidth: '130px'}} className={'td-no-break'}
                                title={item.destino}>{item.destino}</td>
                            <td>{item.cartaPorte}</td>
                            <td>{item.idViaje}</td>
                            <td style={{maxWidth: '150px'}} className={'td-no-break'}
                                title={item.ubicacion}>{item.ubicacion}</td>
                            <td>{dateToString(item.eta?.eta, "yyyy-MM-DD HH:mm")}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <a href={url}>
                    Cuenta espejo
                </a>
            </div>

            <div className={'fluid'} class={'dialog-footer center-buttons'}>
                <Button label="Copiar Tabla" className={'p-button-sm'} onClick={copyHTMLToClipboard} autoFocus/>
                <Button label="Copiar Texto" className={'p-button-sm'} onClick={copiarViajesSeleccionados} autoFocus/>
            </div>
        </Dialog>
    )
}
export default CompartirInformacionModal;