/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import "./CajaChica.css"
import Filters from "../../shared/forms/FormFilter/Filters";
import Field from "../../shared/forms/Field";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import FormCalendarRange from "../../shared/forms/FormFields/FormCalendarRange";
import useCajaApi from "../../api/reportes/useCajaApi";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import moment from "moment";
import SolicitudDetalle from "./Edicion/SolicitudDetalle";
import useForm from "../../shared/forms/useForm";
import { AgCurrencyFormatter, AgDateTimeFormatter } from "../../shared/ag-grid-helper/GridFormatters";
import { GastoRenderer, MontoRenderer } from "./GridRenderers";
import FileViewerFormatter from "../../shared/ag-grid-helper/FileViewerFormatter";
import CheckBoxRenderer from "./CheckBoxRenderer";
import * as numeral from "numeral";
import AbonarACuenta from "./Edicion/AbonarACuenta";
import Cajas from "./Cajas";
import { ButtonHeaders, ShowSideBarButton } from "./GridButtons";
import { v4 as uuidv4 } from 'uuid';
import useSeguridad from '../../Seguridad/useSeguridad';
import constantes from '../../catalogos/constantes';

export const GridContext = React.createContext({});

const filtroInicial = {
    fecha: [moment().startOf('month')._d, moment().startOf('day')._d]
};

const CajaChica = () => {
    const api = useCajaApi();
    const seguridad = useSeguridad();
    const soloLectura = !seguridad.esEditable(constantes.menus.cajaChica);
    const [cajas, setCajas] = useState([]);
    const [datos, setDatos] = useState([]);
    const { values: filter, onChange: onFilterChange, onClear } = useForm(filtroInicial);
    const [gridApi, setGridApi] = useState(null);
    const [grid, setGrid] = useState({ total: 0, idSeleccionado: null, cajaId: 0 });
    const [exportGuid, setExportGuid] = useState(null);
    useEffect(() => {
           if (exportGuid != null)
            api.excel(filter).then();
    }, [exportGuid]);



    const filtrar = async (filtro, paramsApi, cajaId) => {
        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos([]);
        const [cajas, datos] = await Promise.all([api.obtenerCajas(), api.filtrar({ ...filtro, cajaId: cajaId ?? 0 })]);
        const cajaActiva = cajas.find(e => e.id === cajaId) ?? cajas[0];
        if (cajaActiva == null)
            return;

        cajaActiva.activa = true;
        setCajas(cajas);
        setDatos(datos);

        if (cajaId == null)
            setGrid({ ...grid, cajaId: cajaActiva.id });
        agGridHelper.hideLoad(gridApi, paramsApi, true);

    }

    const onChangeCajas = async (cajas, cajaId) => {
        setGrid({ ...grid, cajaId })
        setCajas(cajas);
        filter.cajaId= cajaId;
        await filtrar(filter, gridApi, cajaId);
    }

    const onGridReady = async (params) => {
        setGridApi(params.api, params.api);
        await filtrar(filter, params.api, null);
    };

    const handleFilter = async (filtro) => {
        await filtrar(filtro, gridApi, grid.cajaId);
    }

    const onExport = async () => {
        setExportGuid(uuidv4())
    }


    return (
        <div className="main-screen caja">
            <Cajas cajas={cajas} onChange={onChangeCajas} />
            <div className="divider" />
            <FormFilter columns={6} filter={filter} onChange={onFilterChange} onClear={onClear} onFilter={handleFilter}>
                <Filters>
                    <Field label={"Folio"} field="folio" render={FormTextBox} />
                    <Field label={"Fecha"} field="fecha" render={FormCalendarRange} />
                    <Field label={"Solicitante"} field="solicitante" render={FormTextBox} />
                    <Field label={"Destinatario"} field="destinatario" render={FormTextBox} />
                    <Field label={"Gasto"} field="tipoGastoIds" source="cajachica/tipoGastos" render={FormMultiSelect} />
                    <Field label={"Estatus"} field="estatusIds" source="cajachica/estatusSolicitud"
                        render={FormMultiSelect} />

                </Filters>
                <Buttons filter={true}
                    clear={true}
                >
                </Buttons>
            </FormFilter>
            <div className="divider" />
            <GridContext.Provider value={{ grid, setGrid }}>
                <div className="ag-theme-balham grid-principal cell-flex">
                    <AgGridReact
                        rowSelection={'single'}
                        enableSorting={true}
                        enableFilter={true}
                        pagination={true}
                        onGridReady={onGridReady}
                        frameworkComponents={
                            {
                                goToDetail: ShowSideBarButton,
                                buttonsHeader: ButtonHeaders,
                                gastoRenderer: GastoRenderer,
                                montoRenderer: MontoRenderer,
                                fileViewerRenderer: FileViewerFormatter,
                                checkBoxRenderer: CheckBoxRenderer
                            }
                        }
                        rowData={datos}
                        getRowId={(params) =>
                            `${params.data.tipoMovimiento}_${params.data.id}`
                        }
                        overlayLoadingTemplate={agGridHelper.loadingOverlay}
                        defaultColDef={agGridHelper.defaultColumns}
                        gridOptions={{
                            suppressRowClickSelection: true,
                            suppressCellSelection: true
                        }}
                        rowHeight={40}
                        context={{
                            onExport: onExport
                        }}
                    >
                        <AgGridColumn>
                            <AgGridColumn field="noSolicitud" headerName="NO. SOLICITUD" cellClass={'center'} />
                            <AgGridColumn field="fecha" headerName="FECHA SOLICITUD" valueFormatter={AgDateTimeFormatter} minWidth={160}
                                cellClass={'center'} />
                            <AgGridColumn field="fechaEjecucion" headerName="FECHA CREACION" valueFormatter={AgDateTimeFormatter} minWidth={160}
                                cellClass={'center'} />                           
                            <AgGridColumn field="gasto" headerName="GASTO" minWidth={210} cellRenderer="gastoRenderer"
                                headerClass={'left'} />
                            <AgGridColumn field="solicitante" headerName="SOLICITANTE" minWidth={220} cellClass={'left'}
                                headerClass={'left'} />
                            <AgGridColumn field="destinatario" headerName="DESTINATARIO" minWidth={220} cellClass={'left'}
                                headerClass={'left'} />
                            <AgGridColumn field="monto" headerName="MONTO  SOL." cellRenderer="montoRenderer"
                                headerClass={'left'} />
                            <AgGridColumn field="cartaPorte" headerName="CARTA PORTE" cellClass={'center'} />

                            <AgGridColumn field="montoPorComprobar" headerName="MONTO  NO COMP."
                                valueFormatter={AgCurrencyFormatter} cellClass={'right'}
                                headerClass={'right'} />


                        </AgGridColumn>
                        <AgGridColumn headerName={'EVIDENCIAS'} exportable={false} headerClass={'center'}>
                            <AgGridColumn field="solicitudUrl" headerName="SOLICITUD"
                                cellRenderer={'fileViewerRenderer'}
                                title={'Evidencia Solicitud'}
                                cellClass={'center'} />
                            <AgGridColumn field="pagoUrl" headerName="PAGO" cellRenderer={'fileViewerRenderer'}
                                title={'Evidencia Pago'}
                                cellClass={'center'} />
                            <AgGridColumn field="comprobantesUrl" headerName="GASTO" cellRenderer={'fileViewerRenderer'}
                                title={'Evidencia Gasto'}
                                cellClass={'center'} />
                        </AgGridColumn>
                        <AgGridColumn field="estatus" headerName="ESTATUS" cellClass={'center'} />
                        <AgGridColumn headerName={numeral(grid.total).format('$0,0.00')} exportable={false}
                            headerClass={'total-abonado center'}>
                            <AgGridColumn field="abonado" headerName="ABONADO" cellRenderer={'checkBoxRenderer'}
                                sortable={false}
                                cellClass={'center'} />
                        </AgGridColumn>
                        <AgGridColumn maxWidth={120} headerName="VER" cellRenderer="goToDetail" pinned={'right'}
                            path='/comercial/editar_cotizacion' headerComponent="buttonsHeader" />
                    </AgGridReact>
                </div>
            </GridContext.Provider>

            <SolicitudDetalle consecutivo={grid.idSeleccionado}
                cajaId={grid.editCajaId}
                onSaved={() => {
                    setGrid({ ...grid, data: [], idSeleccionado: null, total: 0 });
                    handleFilter(filter).then();
                }}
                onCancel={() => setGrid({ ...grid, idSeleccionado: null })} />

            <AbonarACuenta soloLectura={soloLectura}
                movimientoId={grid.movimientoId}
                cajaId={grid.editCajaId}
                onSaved={() => {
                    setGrid({ ...grid, data: [], movimientoId: null, total: 0 });
                    handleFilter(filter).then();
                }}
                solicitudesSeleccionadas={grid.solicitudesSeleccionadas}
                onCancel={() => setGrid({ ...grid, movimientoId: null })}
            />
        </div>
    )
}

export default CajaChica;
