import React from "react";
import FormFilter from "../../shared/forms/FormFilter/FormFilter";
import useApiIncidencia from "../../api/administracion/useApiIncidencia";
import {useState} from "react";
import useForm from "../../shared/forms/useForm";
import Filters from "../../shared/forms/FormFilter/Filters";
import Field from "../../shared/forms/Field";
import FormTextBox from "../../shared/forms/FormFields/FormTextBox";
import Buttons from "../../shared/forms/FormFilter/Buttons";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import FormMultiSelect from "../../shared/forms/FormFields/FormMultiSelect";
import FormCalendarRange from "../../shared/forms/FormFields/FormCalendarRange";
import moment from "moment";
import FormInputNumber from "../../shared/forms/FormFields/FormInputNumber";
import FormDropDown from "../../shared/forms/FormFields/FormDropDown";
import constantes from "../../catalogos/constantes";
import {FormattedDate} from "react-intl";
import IncidenciaDetalle from "./incidenciaDetalle";
import Cargo from "./Edicion/cargo";
import {getSessionState} from "../../hooks/useSessionState";
import useSessionState from "../../hooks/useSessionState";
import {useAuth} from "../../Seguridad/AuthProvider";

const filtroInicial = {
    fecha: [moment().startOf("month")._d, moment().startOf("day")._d],
};

const Incidencia = () => {
    const api = useApiIncidencia();
    const [datos, setDatos] = useState([]);
    const [incidenciaId, setIncidenciaId] = useState(null);
    const {values: filter, onChange: onFilterChange, onClear} = useForm(filtroInicial);
    const puedeEditar = true;
    const [agregarCargo, setAgregarCargo] = useState(false);
    const [id, setId] = useState(null);
    const filtroKey = "FiltroIncidencias";
    const [, setFiltro] = useSessionState(filtroKey, {}, filtroInicial);
    const {user: usuario} = useAuth();
    const filtrar = async (filtro) => {
        setDatos(await api.filtrar(filtro ?? getSessionState(filtroKey)));
        setFiltro(filtro);
    };
    const solicitante = {
        id: usuario.empleadoId,
        nombre: usuario.nombre
    };
    const nuevoCargo = (id) => {
        setId(id);
        setAgregarCargo(true);
    };

    function exportar() {
        var filtro = getSessionState(filtroKey);
        if (filtro !== null) api.excel(filtro);
    }

    const ButtonsHeader = () => (
        <div className={"ag-grid header-icons"}>
            <ButtonIcon title={"Descargar"} onClick={exportar} iconName={"BiDownload"}/>

            {puedeEditar && (
                <ButtonIcon
                    title="Agregar"
                    className={"grid-action"}
                    iconName={"MdAddBox"}
                    onClick={() => setIncidenciaId(0)}
                />
            )}
        </div>
    );

    const DateCellRenderer = ({value}) => (
        <div>
            <FormattedDate value={value} weekday="short"/> &nbsp;
            {moment(value).format("DD/MM/YYYY")}
        </div>
    );

    const ButtonsDetail = ({data, context}) => (
        <div className={"ag-grid row-icons"}>
            {data.agregarCargo &&
                <ButtonIcon title={"Agregar Cargo"} iconName={"MdAddBox"} onClick={() => nuevoCargo(data.id)}/>
            }
            <ButtonIcon title={"Editar"} iconName={"FaRegEdit"} onClick={() => context.onEdit(data.id)}/>
        </div>
    );

    return (
        <>
            <FormFilter columns={6} filter={filter} onChange={onFilterChange} onClear={onClear} onFilter={filtrar}>
                <Filters>
                    <Field field={"folio"} label={"Folio"} render={FormTextBox} cols={1}/>
                    <Field field={"cartaPorte"} label={"Carta Porte"} render={FormInputNumber} cols={1}/>
                    <Field
                        field={"operadorId"}
                        label={"Operador"}
                        source="empleados"
                        render={FormDropDown}
                        cols={2}
                        params={{puestoIds: [constantes.puestos.operador]}}
                    />
                    
                    <Field field={"unidadId"} label={"Unidad"} source="unidades" render={FormDropDown} cols={1}/>
                    <Field
                        field={"tipoId"}
                        label={"Tipo de Incidencia"}
                        source="tipoIncidencias"
                        render={FormMultiSelect}
                        params={{departamentoId: 0}}
                        cols={1}
                    />
                    <Field
                        field={"estatusIds"}
                        label={"Estatus"}
                        source="estatusIncidencias"
                        render={FormMultiSelect}
                        cols={1}
                    />
                    <Field field={"fecha"} label={"Fecha"} render={FormCalendarRange} cols={1}/>
                    <Field
                        field={"atendioId"}
                        label={"Atendio"}
                        source="empleados"
                        params={{todasLasEmpresas: true}}
                        render={FormMultiSelect}
                        cols={2}
                        showFilter
                    />
                 
                </Filters>
                <Buttons filter={true} clear={true}></Buttons>
            </FormFilter>
            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                    components={{
                        buttonsHeader: ButtonsHeader,
                        buttonsDetail: ButtonsDetail,
                        dateCellRenderer: DateCellRenderer,
                    }}
                    onGridReady={() => filtrar(filter).then()}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    context={{onEdit: (id) => setIncidenciaId(id)}}
                >
                    <AgGridColumn field="folio" headerName="Folio" cellClass="center"/>
                    <AgGridColumn field="cartaPorte" headerName="Carta Porte" cellClass="center"/>
                    <AgGridColumn field="operador" headerName="Operador"  flex={5} minWidth={240} />
                    <AgGridColumn field="atendio" headerName="Atendio"   flex={5} minWidth={240}/>
                    <AgGridColumn field="unidad" headerName="Unidad" cellClass="center"/>
                    <AgGridColumn
                        field="fechaIncidencia"
                        headerName="Fecha Incidencia"
                        cellClass="center"
                        cellRenderer="dateCellRenderer"
                        maxWidth={150}
                    />
                    <AgGridColumn field="lugarEvento" headerName="Lugar y Evento" cellClass="center"/>
                    <AgGridColumn field="tipo" headerName="Tipo" cellClass="center"/>
                    <AgGridColumn field="subTipo" headerName="SubTipo" cellClass="center"/>
                    <AgGridColumn
                        field="costo"
                        headerName="Costo"
                        cellClass="right"
                        valueFormatter={agGridHelper.currencyFormatter}
                    />
                    <AgGridColumn
                        field="descuento"
                        headerName="Descuento"
                        cellClass="right"
                        valueFormatter={agGridHelper.currencyFormatter}
                    />
                    <AgGridColumn
                        field="saldo"
                        headerName="Saldo"
                        cellClass="right"
                        valueFormatter={agGridHelper.currencyFormatter}
                    />
                    <AgGridColumn field="estado" headerName="Estado" cellClass="center"/>
                    <AgGridColumn
                        maxWidth={80}
                        headerName="VER"
                        pinned={"right"}
                        headerComponent="buttonsHeader"
                        cellClass={"left"}
                        cellRenderer="buttonsDetail"
                    />
                </AgGridReact>
            </div>
            <IncidenciaDetalle
                incidenciaId={incidenciaId}
                puedeEditar={puedeEditar}
                onSaved={() => {
                    filtrar(filter).then();
                    setIncidenciaId(null);
                }}
                onCancel={() => setIncidenciaId(null)}
                onRefresh={() => filtrar(filter).then()}
                empleado={solicitante}/>
            <Cargo
                visible={agregarCargo}
                onClose={() => setAgregarCargo(false)}
                onSaved={() => {
                    filtrar(filter).then();
                    setAgregarCargo(false);
                }}
                incidenciaId={id}
            />
        </>
    );
};

export default Incidencia;
