/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import StatusButton, { ESTATUS_DIARIO, MOTIVO } from "./StatusButton";
import EditModal from "./EditModal";
import { Tooltip } from "primereact/tooltip";
import * as numeral from "numeral";
import { IoPersonSharp } from "react-icons/io5";

const Captura = React.memo(({ unidaDia, dia, otraEmpresa, unidadDiaAnterior, onUpdateDatos, editable }) => {
    const [copia, setCopia] = useState({});
    const [datos, setDatos] = useState({});
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setDatos({
            ...unidaDia,
            fechaCompromiso: unidaDia?.fechaCompromiso != null ? moment(unidaDia.fechaCompromiso)._d : null,
        });
    }, [unidaDia]);

    const onChangeStatus = (estatusId) => {
        setCopia(datos);

        let valoresDefault = {};
        //si el estatusId es nulo o sin estatus significa que es nueva captura, en ese caso copia los datos registrados de capturas pasadas
        //para no tener que guardarlas de nuevo y así generar historial
        const fechaCompromisoVigente = moment(unidadDiaAnterior?.fechaCompromiso).isSameOrAfter(moment(), "days");
        const sinOperadorVigente = moment(datos?.fechaCompromisoOperador).isSameOrAfter(moment(), "days");
        let datosSinOperador = {};
        if (sinOperadorVigente) {
            datosSinOperador.fechaCompromisoOperador = datos.fechaCompromisoOperador;
            datosSinOperador.sinOperador = datos.sinOperador;
        }

        if (
            (datos?.estatusId == null || datos?.estatusId === ESTATUS_DIARIO.sinEstatus) &&
            estatusId === unidadDiaAnterior?.estatusId &&
            fechaCompromisoVigente
        ) {
            valoresDefault = {
                motivo: unidadDiaAnterior.motivo,
                comentarios: unidadDiaAnterior.comentarios,
                fechaCompromiso: unidadDiaAnterior.fechaCompromiso,
            };
        }
        //si no era null y es distinto del nuevo estatus entonces le resetea sus valores
        else if (datos.estatusId !== estatusId) {
            valoresDefault = {
                motivo: null,
                comentarios: null,
                fechaCompromiso: null

            };
        }



        const actualizados = { ...datos, estatusId, ...valoresDefault, ...datosSinOperador };

        setDatos(actualizados);
        if (datos.estatusId !== ESTATUS_DIARIO.operando && estatusId === ESTATUS_DIARIO.operando) {
            // onUpdateDatos(actualizados);
            setVisible(true);
        } else {
            setVisible(true);
        }
    };

    const onSave = (detalle) => {
        onUpdateDatos({ ...datos, ...detalle });
        setVisible(false);
    };

    const onCancel = () => {
        setDatos({ ...copia });
        setVisible(false);
    };

    return (
        <>
            <div className="captura">
                <StatusButton
                    statusId={datos.estatusId}
                    onChange={onChangeStatus}
                    facturacion={unidaDia.facturacion}
                    dia={dia}
                    unidadId={unidaDia.unidadId}
                    otraEmpresa={otraEmpresa}
                    enabled={unidaDia.enabled && editable}
                    coordinador={unidaDia.coordinador}
                    coordinadorNombreCompleto={unidaDia.coordinadorNombreCompleto}
                />
                <div className="subtitulo center">
                    <span className="bold">
                        {datos.fechaCompromiso == null ? "" : moment(datos.fechaCompromiso).format("dd DD/MMM")}{" "}
                    </span>
                </div>

                <div className="titulo center">
                    {datos.motivo?.nombre}{" "}
                    {datos.motivo?.id === MOTIVO.facturando && (
                        <span className="bold"> ({numeral(datos.facturacion).format("$0,0")})</span>
                    )}
                </div>
                <EditModal
                    visible={visible}
                    unidaDia={datos}
                    esDiaActual={true}
                    onUpdateDatos={(d, detalle) => onSave(detalle)}
                    onHide={onCancel}
                />
                <div style={{ fontWeight: "bold", position: "absolute", bottom: "2px", left: "10px" }}>
                    {unidaDia.enRenta}
                </div>
            </div>
            {(unidaDia.comentarios ?? "") !== "" && (
                <div className="img-commentarios">
                    <Tooltip
                        target={`.com-${unidaDia.unidadId}-${unidaDia.fecha}`}
                        style={{ maxWidth: "470px" }}
                        hideDelay={100}
                    >
                        <div className="tooltip-viajes-comentarios">{unidaDia.comentarios}</div>
                    </Tooltip>
                    <img
                        style={{ width: "30px", height: "30px" }}
                        className={`com-${unidaDia.unidadId}-${unidaDia.fecha}`}
                        src="/img/comments.png"
                        alt="comentarios"
                    />
                </div>
            )}
            {unidaDia.sinOperador && (
                <div className={"sin-operador-card"}>
                    <IoPersonSharp fill={"red"} />
                </div>
            )}
        </>
    );
});

export default Captura;
