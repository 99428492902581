import useServiceFactory from "../serviceFactory";

const apiUrl = `/operaciones/viajes/seguimientoMonitoreo`;

const useApiViajeSeguimientoMonitoreo = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        filtrar: async (api, params) => service.get(`${apiUrl}/${api}`, { params, showLoading: true}),
        getPosiciones: async (params) => service.post(`${apiUrl}/posicion/unidades`, params,{  showLoading: false, showSuccess:false, ignoreError:true}),
        getEtas: async (params) => service.get(`${apiUrl}/getEtas`, {params,  showLoading: false, showSuccess:false, ignoreError:true}),
        getIndicadoresViajes: async (params) => service.post(`${apiUrl}/viajes/indicadores`, params,{  showLoading: false, showSuccess:false}),
        getRuta: async (unidadId,params)=> service.get(`${apiUrl}/unidades/${unidadId}/ruta`, {params, showLoading: true}),
        getNombreRutas: async (params) => service.get(`${apiUrl}/viajes/nombreRutas`, { params, showLoading: true}),
        generarToken: async (params, config)=> service.post(`${apiUrl}/GenerarTokenSeguimiento`, params ,{  showLoading: true, ...config}),
        getViajeById: async (viajeId, params) => service.get(`${apiUrl}/viajes/${viajeId}`, {params, showLoading: true}),
        iniciar: async (viajeId, params) => service.post(`${apiUrl}/${viajeId}/iniciar`, {params, showLoading: true}),
        atenderBitacora: async (viajeId, bitacoraId, params) => service.post(`${apiUrl}/${viajeId}/Bitacora/${bitacoraId}`, params,{ showLoading: true}),
        excel: async (params) => service.get(`${apiUrl}/excel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `Seguimiento.xlsx`
        }),
        exelRuta: async (params) => service.get(`${apiUrl}/excelRuta`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `ruta.xlsx`
            })

};
};

export default useApiViajeSeguimientoMonitoreo;
