import moment from "moment";
import * as numeral from "numeral";

export const AgDateFormatter = ({value})=>{
    return value != null ? moment(value).format('MMM D, YYYY') : '';
}

export const AgBoolFormatter = ({value})=>{
    return value ? 'Si' : 'No';
}

export const AgDateTimeFormatter = ({value})=>{
    return value != null ? moment(value).format('MMM D, YYYY HH:MM') : '';
}


export const AgCurrencyFormatter = ({value, colDef, ...props})=>{
    const precision = colDef.precision ?? 2;
    const format = precision === 0 ? '' : ('.' +  ''.padStart(precision));
    return value == null ? '' : numeral(value).format(`$ 0,0${format}`);
}

export const AgNumberFormatter = ({value,colDef, ...props})=>{
    const precision = colDef.precision ?? 2;
    const format = precision === 0 ? '' : ('.' +  ''.padStart(precision));
    return value == null ? '' : numeral(value).format(`0,0${format}`);
}

export const AgHourFormatter = ({value,colDef, ...props})=>{
    const precision = colDef.precision ?? 0;
    const format = precision === 0 ? '' : ('.' +  ''.padStart(precision));
    return value == null ? '' : numeral(value).format(`0,0${format}`);
}