/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import PropTypes from "prop-types";

const Field = ({render, onChange, label, field, formDisabled,  values, onDestroy, ...props}) => {

    useEffect(() => {
        return () => {
            if (onDestroy != null)
                onDestroy();
        };
    }, []);


    const handleChange = (value) => {
            onChange({...values, [field]:value});
    }

    const handleParentChange = (newValues) =>{
        onChange({...values, ...newValues});
    }

    return (
        <>
            {label &&
                <span className="ex-field-label" style={{marginBottom: '0.5rem', paddingLeft: '2px'}}>
                    {typeof label === 'function' ? label({parent:values}) : label}
                </span>
            }
            {render({
                ...props,
                parent:values,
                field,
                formDisabled,
                value:values[field],
                onChange: handleChange,
                onParentChange:handleParentChange
            })
            }
        </>
    )
}

export default Field;

Field.propTypes = {
    field: PropTypes.string.isRequired,
    cols: PropTypes.number,
    required: PropTypes.bool,
    render: PropTypes.func.isRequired,
    cssClass: PropTypes.string,
    className: PropTypes.object,
    cellStyle: PropTypes.object,
    source: PropTypes.string,
    disabled: PropTypes.bool,
    isVisible:PropTypes.func, //función para indicar si el campo es visible si el field no implementa la función es visible por default
    validators:PropTypes.array // arreglo de funciones de validación extras aparte del required
    //ejemplo   <Field isVisible={(values)=>values.visible}
};
