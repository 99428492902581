/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer} from "react";
import {Button} from "primereact/button";
import useApiDirecciones from "../../api/catalogos/useApiDirecciones";
import Direccion from "../../catalogos/direccion";
import direccionValidacionEsquema from "./direccionValidacionEsquema";
import formReducer from "../../shared/forms/formReducer";
import FormHandler from "../../shared/forms/formHandler";
import FieldDropDown from "../../shared/controls/fieldDropDown";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";
import alerts from "../../shared/alerts";
import constantes from "../constantes";

const defaultEntity = () => ({
    entity: {
        direccion: {colonia: "NA"}
    }
});

const DetalleDireccion = ({direccionId, onSave, soloLectura}) => {
    const guid = Math.random();

    const [{entity, errors}, dispatch] = useReducer(
        formReducer, {}, defaultEntity
    );

    const api = useApiDirecciones();

    const formHandler = new FormHandler({
        validationSchema: direccionValidacionEsquema,
        api,
        dispatch,
    });


    useEffect(() => {
        async function cargar() {
            const dir = await api.obtener(direccionId);
            const direccion = {entity: {id: direccionId, direccion: dir}};
            dispatch({type: "reset", getDefault: () => direccion ?? defaultEntity});
        }

        if (direccionId > 0) {
            cargar().then();
        }
    }, []);


    const update = (value, name) => {
        dispatch({type: 'update', value, name});
        if (value.cambioDireccion === true) {
            dispatch({type: 'update', value: value.telefono, name: 'telefono'});
        }
    };


    const updateGeocerca = (value) => {
        dispatch({type: 'update', value: {...entity.direccion, geocerca: value}, name: 'direccion'});

    };


    const guardar = async () => {
        await formHandler.save(entity);

        if (entity.direccion.pais.id ===constantes.paises.mexico && entity.direccion.rfc === "XEXX010101000") {
            alerts.aviso("RFC no aceptado para direccion en México.");
            return;
        }

        if (entity.direccion.pais.id === constantes.paises.eua && entity.direccion.rfc === "XAXX010101000") {
            alerts.aviso("RFC no aceptado para direccion en el extranjero.");
            return;
        }



        if (formHandler.valdation.result) {
            onSave();
        }
    };


    return (
        <>
            <div className="form-container full-height">
                <h5>Dirección</h5>
                <div className="form-row">
                    <ReadOnlyProvider defaultReadOnly={soloLectura}>
                        <Direccion value={entity?.direccion} name="direccion" onChange={update}
                                   mostrarCambioNombre={true} readOnly={soloLectura}
                                   errors={errors?.direccion}/>

                        <FieldDropDown time={800} name="geocerca" label="Geocerca" source="geocercas"
                                       params={{id: guid}} colMd={6} filter
                                       errors={errors?.direccion}
                                       value={entity?.direccion.geocerca} onChange={updateGeocerca}/>
                    </ReadOnlyProvider>
                </div>
                <div className="form-footer">
                    {!soloLectura &&
                        <Button label="Guardar" type="button" onClick={guardar}/>
                    }
                </div>
            </div>
        </>
    )
};

export default DetalleDireccion;
