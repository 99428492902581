import React, { useState } from "react";
import FieldTextBox from "../../../shared/controls/fieldTextBox";
import FieldDropDown from "../../../shared/controls/fieldDropDown";
import FieldCalendar from "../../../shared/controls/fieldCalendar";
import produce from "immer";
import FieldRadioButtonList from "../../../shared/controls/fieldRadioButtonList";
import FieldInputNumeroRfc from "../../../shared/controls/customInputs/fieldInputRfc";
import FieldInputNumeroCurp from "../../../shared/controls/customInputs/fieldInputCurp";
import FieldInputNumeroPhone from "../../../shared/controls/customInputs/fieldInputPhone";
import FieldInputAccesoElectronico from "../../../shared/controls/customInputs/fieldInputAccesoElectronico";
import constantes from "../../constantes";
import FieldAutoComplete from "../../../shared/controls/fieldAutoComplete";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";

const Generales = ({ generales, asignacion, codigoEmpleado, name, onChange, errorsDatosGenerales, errorsAsignacion }) => {
    const validateRfc = require('validate-rfc');
    const [response, setResponse] = useState({ isValid: true });
    async function actualizar(value, propertyName) {
        if (propertyName === "rfc") {
            setResponse(validateRfc(value));
            if (response.isValid) {
                const nuevo = produce(generales ?? {}, draft => {
                    draft[propertyName] = response.rfc;
                });

                onChange(nuevo, name);
            }
        }
        else {
            const nuevo = produce(generales ?? {}, draft => {
                draft[propertyName] = value
            });

            onChange(nuevo, name);
        }
    }

    async function actualizarAsignacion(value, propertyName) {
        const nuevo = produce(asignacion ?? {}, draft => {
            draft[propertyName] = value;
            draft.esOperador = draft?.puesto?.id === constantes.puestos.operador || draft?.tipoEmpleado?.id === constantes.tiposEmpleado.utilitario
        });
        const esCambioEnTipoEmpleado = propertyName === 'tipoEmpleado';

        onChange(nuevo, 'asignacion', esCambioEnTipoEmpleado);
    }

    return (
        <>
            <div className="form-row">
                <h5 className="col-12">Generales</h5>

                <FieldTextBox name="codigoEmpleado" label="Id Empleado" colMd={6} disabled={true} errors={errorsDatosGenerales}
                    value={codigoEmpleado} />
                <FieldCalendar name="fechaIngreso"
                    label="Fecha de Ingreso"
                    className="small"
                    value={generales?.fechaIngreso}
                    showButtonBar={false}
                    colMd={6}
                    errors={errorsDatosGenerales}
                    onChange={actualizar} />
                <FieldTextBox required name="nombre" label="Nombre(s)" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.nombre} onChange={actualizar} />
                <FieldTextBox required name="apellidoPaterno" label="Apellido Paterno" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.apellidoPaterno} onChange={actualizar} />
                <FieldTextBox required name="apellidoMaterno" label="Apellido Materno" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.apellidoMaterno} onChange={actualizar} />
                <FieldTextBox name={"alias"} label={"Alias"} colMd={6} errors={errorsDatosGenerales}
                              value={generales?.alias} onChange={actualizar}/>

                <FieldInputNumeroCurp required name="curp" label="CURP" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.curp} onChange={actualizar} />


                <div className={`form-group col-md-6 col-sm-12}`}>
                    <label>&nbsp;</label>
                    <FieldRadioButtonList name="sexo" label="Sexo" colMd={6} source="sexos" valueIsObject={true} errors={errorsDatosGenerales}
                        value={generales?.sexo} onChange={actualizar} />
                </div>

                <FieldInputNumeroRfc required name="rfc" label="RFC" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.rfc} onChange={actualizar} />
                <FieldDropDown name="estadoCivil" required label="Estado Civil" colMd={6} source="estadosCiviles" filter errors={errorsDatosGenerales}
                    value={generales?.estadoCivil} onChange={actualizar} />
                {!response?.isValid &&
                    <div className={`form-group col-md-12 col-sm-12}`}>
                        <label className="text-danger">Formato Invalido</label>
                    </div>
                }
                <h5 className="col-12">Fecha y Lugar de Nacimiento</h5>
                <FieldCalendar required name="fechaNacimiento" label="Fecha de Nacimiento" colMd={6} errors={errorsDatosGenerales} className="small"
                    value={generales?.fechaNacimiento} onChange={actualizar} />
                <FieldDropDown required name="estadoNacimiento" label="Estado de Nacimiento" colMd={6} source="estados" filter errors={errorsDatosGenerales}
                    value={generales?.estadoNacimiento} onChange={actualizar} />

                <h5 className="col-12">Datos de Contacto</h5>


                <FieldInputNumeroPhone required name="telefonoPersonal" label="Telefono Personal" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.telefonoPersonal} onChange={actualizar} />

                <FieldInputNumeroPhone name="telefonoABC" label="Telefono Ximple (Airbag)" colMd={6} errors={errorsDatosGenerales}
                    value={generales?.telefonoABC} onChange={actualizar} />


                <FieldTextBox name="correo" label="Correo" colMd={12} errors={errorsDatosGenerales}
                    value={generales?.correo} onChange={actualizar} />

                <h5 className="col-12">Uniforme</h5>
                <FieldDropDown required name="talla" label="Talla" colMd={6} source="tallas" filter errors={errorsDatosGenerales}
                    value={generales?.talla} onChange={actualizar} />

                <h5 className="col-12">Asignación</h5>
                <FieldDropDown required name="departamento" label="Departamento" colMd={6} source="departamentos" filter errors={errorsAsignacion} className="dropdown-bottom"
                    value={asignacion?.departamento} onChange={actualizarAsignacion} />
                <FieldAutoComplete name="jefeInmediato" label="Jefe Inmediato" colMd={6} source="empleados" filter errors={errorsAsignacion} className="dropdown-bottom"
                    params={{ todasLasEmpresas: true }} value={asignacion?.jefeInmediato} onChange={actualizarAsignacion} />
                <FieldAutoComplete name="headHunter" label="Head Hunter" colMd={6} source="empleados" filter errors={errorsAsignacion} className="dropdown-bottom"
                    params={{ todasLasEmpresas: true }} value={asignacion?.headHunter} onChange={actualizarAsignacion} />
                <FieldAutoComplete name="reclutador" label="Reclutador" colMd={6} source="empleados" filter errors={errorsAsignacion} className="dropdown-bottom"
                    params={{ todasLasEmpresas: true }} value={asignacion?.reclutador} onChange={actualizarAsignacion} />
                <FieldDropDown required name="puesto" label="Puesto" colMd={6} source="puestosDepartamento" errors={errorsAsignacion} filter className="dropdown-bottom"
                    value={asignacion?.puesto} params={asignacion?.departamento} onChange={actualizarAsignacion} />
            
                 <FieldInputAccesoElectronico name="accesoElectronico" label="Acceso Electronico" colMd={6}
                                value={asignacion?.accesoElectronico} onChange={actualizarAsignacion} />

                <FieldDropDown required name="tipoEmpleado" label="Tipo" colMd={6} source="tiposEmpleado" errors={errorsAsignacion} filter className="dropdown-bottom"
                    value={asignacion?.tipoEmpleado} onChange={actualizarAsignacion} params={asignacion?.puesto} fireChangeOnValueChanged={true} />
                    {asignacion?.esOperador &&
                         <FieldAutoComplete name="gestorOperadores" label="Gestor de operadores" colMd={6} source="empleadosGestores" filter errors={errorsAsignacion} className="dropdown-bottom"
                         params={{ todasLasEmpresas: true }} value={asignacion?.gestorOperadores} onChange={actualizarAsignacion} />
                    }
                     {asignacion?.esOperador &&
                         <FieldAutoComplete name="instructorPruebaManejo" label="Prueba de Manejo Instructor" colMd={6} source="empleadosInstructores" filter errors={errorsAsignacion} className="dropdown-bottom"
                         params={{ todasLasEmpresas: true }} value={asignacion?.instructorPruebaManejo} onChange={actualizarAsignacion} />
                    }
                    {asignacion?.esOperador &&
                        <FieldCheckBox label="Prueba de Manejo" name="pruebaManejo" colMd={2}  value={asignacion?.pruebaManejo} onChange={actualizarAsignacion} />
                    }
                    {asignacion?.esOperador &&
             
                        <FieldCheckBox label="Posturero" name="esPosturero" value={asignacion?.esPosturero} colMd={1}  onChange={actualizarAsignacion} />
                        
                    }
                    {asignacion?.esOperador &&
             
                        <FieldCheckBox label="Verificación de Seguridad" name="VSeguridad" value={asignacion?.vSeguridad} colMd={2}  onChange={actualizarAsignacion} />
                        
                    }
       
                    
            </div>
        </>
    )
};

export default Generales;
