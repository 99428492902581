    /* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {MultiSelect} from "primereact/multiselect";
import useSourceUtils from "../../useSouceUtils";
    import useInputValidation from "./useInputValidation";

const FormMultiSelect = ({value,onChange, parent, field, options, source, orderBy, validators, params, onValidate,disabled,formDisabled,maxSelectedLabels, hideFilter, ...props}) => {

    const {
        currentOptions,
        setSelectedId,
        getSelected
    } = useSourceUtils(value, options, source, params, true, true,  orderBy);

    const {validationErrorCss} = useInputValidation({
        value,
        field,
        required:false,
        validators,
        onValidate,
        parent
    });


    const onDropChange =(event) => {
        const ids= event.value?.map(e=>e.id)??[];
        setSelectedId(ids)
        onChange(ids, event.value);
    }



    return (
        <>
            <MultiSelect
                value={getSelected()}
                id={field}
                style={{width:'100%'}}
                options={currentOptions}
                onChange={onDropChange}
                className={`${validationErrorCss}`}
                optionLabel="nombre"
                filterBy="nombre"
                placeholder={`Seleccionar`}
                filter={!hideFilter}
                maxSelectedLabels={maxSelectedLabels??2}
                // onBlur={onBlur}
                disabled={disabled??formDisabled}
                {...props}
            />
        </>
    )
}

export default FormMultiSelect;
