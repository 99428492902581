/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {RadioButton} from "primereact/radiobutton";
import useApiSource from "../../api/source";
import {useReadOnly} from "../forms/ReadOnlyProvider";


const FieldRadioButtonList = ({colMd, colSm, label, name, value, onChange, setFieldValue,
                                  options, source, flexDirection, className, disabled,
                                  valueIsObject, errors
                              }) => {

    const [listOptions, setListOptions] = useState(options == null ? [] : options);
    const [selectedValue, setSelectedValue] = useState(0);
    const apiSpurce = useApiSource();

    const {readOnly} = useReadOnly()??{};
    disabled = disabled ?? readOnly;


    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    useEffect(() => {
        async function loadOptions() {
            if (source != null && source.length > 0) {
                const options = await apiSpurce.obtener(source);
                if (options != null)
                    setListOptions(options);
            }
        }

        loadOptions();
    }, [source]);

    function onSelected(newValue) {
        if (newValue === selectedValue){
            return;
        }
        setSelectedValue(newValue);
        if (onChange != null)
            onChange(newValue,name)
    }
    
    function onSelectOption(newValue) {
        if (newValue === selectedValue?.id){
            return;
        }
        const option = getOption(newValue);
        setSelectedValue(option);
        if (onChange != null)
            onChange(option,name)
    }
    
    function getOption(value) {
        const findValue = listOptions.find(e=>e.id ===value);
        if (findValue != null)
            return findValue;

        return value;
    }

    return (
        <div className={`field-radiobutton-list ${className??''} ${ (errors?.[name]) ? 'error' : ''}`} style={{flexDirection:flexDirection}}>
            {listOptions.map((option, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className={`p-field-radiobutton`}>
                            {valueIsObject !== true &&
                            <RadioButton inputId={`${name}_${index}`} name={option.nombre} value={option.id}
                                         disabled={disabled}
                                         onChange={(e) => onSelected(e.value)}
                                         checked={selectedValue === option.id}/>
                            }
                            {
                                valueIsObject === true &&
                                <RadioButton inputId={`${name}_${index}`} name={option.nombre} value={option.id}
                                             disabled={disabled}
                                             onChange={(e) => onSelectOption(e.value)}
                                             checked={selectedValue?.id === option.id}/>
                            }
                            <label htmlFor={`${name}_${index}`}>{option.nombre}</label>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    )
};

export default FieldRadioButtonList;

